import * as React from "react";
import { Box } from "@mui/material";
import PatientData from "./components/PatientData";
import ReportData from "./components/ReportData";
import ResumeInformationHTA from "./components/ResumeInformationHTA";
import Header from "./components/Header";
import HTACharts from "../patients/components/HTACharts";
import ColorBarMeasurement from "./components/ColorBarMeasurement";
import Morisky from "./components/Morisky";
import HTATables from "./components/HTATables";
import { useTranslation } from "react-i18next";
const HTAReport = (props) => {
  const [pages, setPages] = React.useState([]);
  const { t } = useTranslation();
  React.useEffect(() => {
    let pagesToSend = [
      document.getElementById("page1"),
      document.getElementById("page2"),
      pages[0],
      pages[1],
    ];
    props.printPages(pagesToSend);
  }, [pages]);

  const styles = {
    container: {
      margin: "0px",
    },
    boxTitles: {
      color: "#575565",
      fontSize: "15px",
      fontWeight: "700",
      fontFamily: "Poppins",
    },
  };

  return (
    <Box sx={styles.container}>
      <Box id="page1">
        <Header title={t("home_monitoring")} />
        <PatientData profile_data={props.data?.data?.profile_data} />
        <ReportData
          date_from={props.data?.date_from}
          date_to={props.data?.date_to}
          type={props.data?.type}
          pathology={"HTA"}
          medicines={
            props.data?.data?.study_data?.medications?.used
              ? props.data?.data?.study_data?.medications?.medicines
                  .map((med) => med.medicine.name)
                  .join(", ")
              : ["NO"]
          }
        />
        <ResumeInformationHTA summary={props.data?.data?.summary} />
        <ColorBarMeasurement
          title={t("max_sistolic")}
          centerColor={true}
          labels={["<100 - Baja", "100 - 135 - Normal", ">135 - Alta"]}
          colors={["#FFE663", "#63DB76", "#D13A3A"]}
          value={
            props.data?.data?.summary?.measurements?.averages?.general?.systolic
          }
          percentage={
            props.data?.data?.summary?.measurements?.averages?.general
              ?.systolic / 135
          }
        />
        <ColorBarMeasurement
          title={t("mion_diastolic")}
          centerColor={true}
          labels={["<60 - Baja", "60 - 85 - Normal", ">85 - Alta"]}
          colors={["#FFE663", "#63DB76", "#D13A3A"]}
          value={
            props.data?.data?.summary?.measurements?.averages?.general
              ?.diastolic
          }
          percentage={
            props.data?.data?.summary?.measurements?.averages?.general
              ?.diastolic / 85
          }
        />
        {props.data?.data?.morisky ? (
          <Morisky morisky={props.data?.data?.morisky} />
        ) : (
          <></>
        )}
      </Box>
      <Box id="page2">
        <Box sx={[styles.boxTitles, { fontSize: "16px" }]} mt={"20px"}>
          {t("collected_data")}
        </Box>
        <HTATables
          title={t("blood_pressure")}
          data={props.data?.data?.measurements}
        />
        <HTATables
          title={t("heart_rate")}
          data={props.data?.data?.measurements}
        />
      </Box>
      <Box>
        <Box paddingRight={"60px"}>
          <HTACharts
            pages={(pages) => {
              setPages(pages);
            }}
            json={props.data?.data?.graphs}
            saveChartImage={() => {}}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default HTAReport;
