import * as React from "react";
import { InputLabel, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const HTAModality = (props) => {
  const { t } = useTranslation();
  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
      marginTop: "20px",
    },
    title: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
      whiteSpace: "pre-wrap",
    },
    labels: {
      whiteSpace: "pre-wrap",
      marginLeft: "15px",
      fontSize: "16px",
      color: "#3988E3",
      fontWeight: 600,
    },
  };

  return (
    <Box sx={styles.container}>
      <Box display={"flex"} alignItems={"center"}>
        <InputLabel sx={styles.title}>{t("use_mode")}</InputLabel>
        <InputLabel sx={styles.labels}>
          {props.modality == "1"
            ? t("home_blood_pressure_monitoring")
            : t("personal_blood_pressure_monitoring")}
        </InputLabel>
      </Box>
    </Box>
  );
};
export default HTAModality;
