import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const styles = {
  boxTitles: {
    color: "#575565",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
  itemsTitle: {
    color: "#3657D9",
    fontSize: "11px",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
};

const ResumeInformationHTA = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={[styles.boxTitles, { fontSize: "16px" }]} mt={"10px"}>
        {t("resum")}
      </Box>
      <Box sx={[styles.itemsTitle, { color: "#737589" }]} mt={"10px"}>
        {t("ware_made")} {props.summary?.measurements?.count}{" "}
        {t("measurements")}. <br />
        {t("average_bp")}{" "}
        {props.summary?.measurements?.averages?.general?.systolic}/
        {props.summary?.measurements?.averages?.general?.diastolic} mmHg,
        {t("average_morning")}{" "}
        {props.summary?.measurements?.averages?.morning?.systolic}/
        {props.summary?.measurements?.averages?.morning?.diastolic} mmHg y
        {t("evening")}{" "}
        {props.summary?.measurements?.averages?.afternoon?.systolic}/
        {props.summary?.measurements?.averages?.afternoon?.diastolic} mmHg.{" "}
        <br />
        {t("maximum_systolic")} {props.summary?.measurements?.max?.systolic}{" "}
        mmHg {t("maxim_diastolic")}
        {props.summary?.measurements?.max?.diastolic} mmHg. <br />
        {t("register_bp_sup")}{" "}
        {props.summary?.measurements?.outliers_percent?.systolic}%{" "}
        {t("for_systolic_and")}{" "}
        {props.summary?.measurements?.outliers_percent?.diastolic}%
        {t("for_diastolic")} <br />
        {t("values_weekly_average")}{" "}
        {props.summary?.measurements?.averages?.general?.systolic}/
        {props.summary?.measurements?.averages?.general?.diastolic} {t("that")}{" "}
        {props.summary?.measurements?.outliers_percent?.exceeded
          ? t("exceed")
          : t("no_exceed")}{" "}
        {t("the_values_expected_for_protocol")} <br />
      </Box>
    </Box>
  );
};
export default ResumeInformationHTA;
