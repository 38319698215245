import * as React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import dotenv from "react-dotenv";
import WithMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const ImprovementIdeasCreatePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const [errors, setErrors] = React.useState({});
  const [isAttached, setIsAttached] = React.useState(false);
  const [form, setForm] = React.useState({
    attachments: [],
    comment: "",
    user_id: user.user.id,
  });

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const removeFiles = () => {
    setForm({ ...form, attachments: [] });
    setIsAttached(false);
  };

  const setAttachments = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      let attachments = form.attachments;
      files.map((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setIsAttached(true);
        };
        reader.readAsDataURL(file);
        attachments.push(file);
      });
      setForm({ ...form, attachments: attachments });
    }
  };

  const attachFile = () => {
    hiddenFileInput.current.click();
  };

  const saveImprovementIdeas = () => {
    setLoading(true);
    axios
      .post(
        new URL("/institutions/v1/improvement-ideas", dotenv.API_URL).href,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() => props.showMessage(t("report_was_created"), () => navigate(0)))
      .catch((error) =>
        props.showMessage(error.response.data.message, () => {}, "error")
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const styles = {
    fields: {
      borderRadius: "12px",
      backgroundColor: "#FCFCFC",
      border: "1px solid #869AE8",
      padding: "10px 12px",
      color: "#869AE8",
      width: "100%",
    },
    attachedFile: {
      width: "15px",
      height: "15px",
      fontSize: "10px",
      backgroundColor: "#3988E3",
      borderRadius: "50%",
      position: "absolute",
      right: "0px",
      top: "0px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "600",
    },
    button: {
      width: "100%",
      marginTop: "20px",
    },
  };

  return (
    <div>
      <Grid xs={12} sx={{ mt: 4 }}>
        <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
          <Box
            sx={{
              color: "#737589",
              fontSize: "14px",
              fontFamily: "Poppins",
              marginBottom: "20px",
            }}
          >
            {t("we_like_know_opinion")}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <textarea
              style={styles.fields}
              value={form.comment}
              id="comment"
              rows={4}
              multiline
              name="comment"
              onChange={handleChange}
            />
            <IconButton
              sx={{ marginLeft: "10px", height: "40px" }}
              onClick={attachFile}
            >
              <img src={"/chat_assets/attach.png"} />
              <input
                multiple
                id="file_id"
                type="file"
                ref={hiddenFileInput}
                onChange={setAttachments}
                style={{ display: "none" }}
              />
              {isAttached ? (
                <Box sx={styles.attachedFile}>{form.attachments.length}</Box>
              ) : (
                <></>
              )}
            </IconButton>
            {isAttached ? (
              <IconButton onClick={removeFiles}>
                <img style={{ height: "19px" }} src="delete.png" />
              </IconButton>
            ) : (
              <></>
            )}
          </Box>
          <div align="center">
            <Button
              disabled={form.comment.length < 5}
              sx={styles.button}
              variant="contained"
              color="primary"
              onClick={saveImprovementIdeas}
            >
              {t("send")}
            </Button>
          </div>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default WithMessages(ImprovementIdeasCreatePage);
