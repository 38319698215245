import {
  Button,
  Grid,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React from "react";
import { useTranslation } from "react-i18next";

const AddMedicineRow = (props) => {

  const { t } = useTranslation();
  const [form, setForm] = React.useState({
    medicine_id: props.selectedMedicine?.id,
    frequency_type: null,
    schedules: [
      {
        time: "6:00",
        dose: 0,
      },
      {
        time: "08:00",
        dose: 0,
      },
      {
        time: "10:00",
        dose: 0,
      },
      {
        time: "12:00",
        dose: 0,
      },
      {
        time: "14:00",
        dose: 0,
      },
      {
        time: "16:00",
        dose: 0,
      },
      {
        time: "18:00",
        dose: 0,
      },
      {
        time: "20:00",
        dose: 0,
      },
      {
        time: "22:00",
        dose: 0,
      },
      {
        time: "24:00",
        dose: 0,
      },
    ],
    observations: "",
  });

  const styles = {
    select: {
      width: "100%",
      borderRadius: "12px",
      border: "1px solid #869AE8",
      background: "#FFF",
      color: "#869AE8",
      fontSize: "16px",
      fontWeight: 500,
      textAlign: "left",
      padding: "5px",
    },
    drugs: {
      width: "279px",
      height: "Fixed (68px)px",
      padding: "0px",
      gap: "12px",
    },
    howMuch: {
      width: "111px",
      height: "68px",
      padding: "12px 24px 12px 24px",
      gap: "12px",
      border: "0px 0px 1px 0px",
      opacity: "0px",
    },
    hs: {
      minWidth: "80px",
      height: "68px",
      gap: "12px",
      padding: "0px",
    },
    options: {
      width: "91px",
      height: "44px",
      radius: "12px",
      border: "1px",
    },
    buttonPlus: {
      width: "39px",
      minWidth: "0",
      height: "29.37px",
      backgroundColor: "#3657D9",
      color: "white",
      fontSize: "16px",
    },
  };

  React.useEffect(() => {
    props.updateMedicationPlan(form);
  }, [form]);

  const handleIncrement = (index) => {
    if (form.frequency_type !== 1) {
      if (form.frequency_type == 2) {
        if (sumSchedules() >= 1) {
          return;
        }
      }
      const updatedSchedules = [...form.schedules];
      updatedSchedules[index].dose += 0.25;
      setForm({ ...form, schedules: updatedSchedules });
    }
  };

  const handleDecrement = (index) => {
    if (form.frequency_type !== 1) {
      const updatedSchedules = [...form.schedules];
      if (updatedSchedules[index].dose >= 0.25) {
        updatedSchedules[index].dose -= 0.25;
        setForm({ ...form, schedules: updatedSchedules });
      }
    }
  };
  
  const handleChange = (event) => {
    if (event.target.name == "frequency_type" && event.target.value == 1) {
        const updatedSchedules = [...form.schedules];
        updatedSchedules.forEach((s) => s.dose = 0);
        setForm({ ...form, [event.target.name]: event.target.value, schedules: updatedSchedules });
    } else {
        setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  const sumSchedules = () => {
    return form.schedules.reduce((total, schedule) => total + schedule.dose, 0);
  }

    return (
        <TableRow key={props.selectedMedicine?.id}>
            <TableCell padding="0">
                <Button
                    onClick={props.deleteMed}
                    sx={{
                        height: "21px",
                        borderRadius: "6.13px",
                        width: "21px",
                        minWidth: "0",
                    }}
                >
                    <img src="/deleteicon.png" />
                </Button>
            </TableCell>
            <TableCell sx={styles.drugs}>
                {props.selectedMedicine?.name +
                    "  " +
                    props.selectedMedicine?.presentation +
                    "  " +
                    "  " +
                    props.selectedMedicine?.potency +
                    "  " +
                    "(" +
                    props.selectedMedicine?.monodrug +
                    ")"}
            </TableCell>
            <TableCell>
                <Select
                    name="frequency_type"
                    value={form.frequency_type}
                    onChange={handleChange}
                    sx={styles.select}
                >
                    <MenuItem value={1}>{t("anticoagulated")}</MenuItem>
                    <MenuItem value={2}>{t("one_per_day")}</MenuItem>
                    <MenuItem value={3}>{t("other")}</MenuItem>
                </Select>
            </TableCell>
            <>
                {form.schedules.map((schedule, index) => (
                    <TableCell sx={styles.hs} key={index}>
                        <Grid sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                            <ArrowLeftIcon
                                style={{
                                    color: "#869AE8",
                                    height: "18px",
                                    width: "18px",
                                }}
                                onClick={() => handleDecrement(index)}
                            />
                            <div style={{ margin: "0 5px" }}>
                                <Typography
                                    style={{
                                        width: "auto",
                                        height: "18px",
                                        color: "#3657D9",
                                        padding: "0px",
                                    }}
                                >
                                    {schedule.dose}
                                </Typography>
                            </div>
                            <ArrowRightIcon
                                style={{
                                    color: "#869AE8",
                                    height: "18px",
                                    width: "18px",
                                }}
                                onClick={() => handleIncrement(index)}
                            />
                        </Grid>
                    </TableCell>
                ))}
            </>
            <TableCell>
                <TextField
                    multiline={true}
                    value={form.observations}
                    name="observations"
                    onChange={handleChange}
                />
            </TableCell>
            <TableCell>
                <Button sx={styles.buttonPlus} onClick={props.isSelectingMedicine}>
                    +
                </Button>
            </TableCell>
        </TableRow>
    );
};
export default AddMedicineRow;
