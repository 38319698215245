import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MainMenu from "./MainMenu";
import { Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Avatar from "@mui/material/Avatar";

import theme from "../theme/vs-theme";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import Pusher from "pusher-js";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/base";

const Layout = (props) => {
  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [unseenMessages, setUnseenMessages] = React.useState(0);
  const [role, setRole] = React.useState("");
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [showSurveys, setShowSurveys] = React.useState(true);

  React.useEffect(() => {
    const manageWindowSizeChange = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", manageWindowSizeChange);
    return () => {
      window.removeEventListener("resize", manageWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    if (
      user.user.pathologies?.length == 1 &&
      user.user.pathologies[0]?.name == "HTA"
    ) {
      setShowSurveys(false);
    }
  }, [user]);

  const defaultWidth = windowWidth - 350 + "px";

  const getContacts = () => {
    const fetchData = async () => {
      const url = new URL("/chat/v1/chatify/api/getContacts", dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.user.chat_user_token,
          },
        })
        .then((response) => {
          setUnseenMessages(response.data?.unseenCount);
        });
    };
    fetchData();
  };

  const viewedMessages = () => {
    getContacts();
  };

  const getRoles = (roles) => {
    let userRoles = [];
    roles.forEach((role) => {
      userRoles.push(role.name);
    })
    return userRoles;
  };

  React.useEffect(() => {
    if (user?.user?.roles) {
      setRole(getRoles(user?.user?.roles));
      if (getRoles(user?.user?.roles).includes("doctor")) {
        if (!window.location.pathname.startsWith("/chat")) {
          const pusher = new Pusher(dotenv.PUSHER_APP_KEY, {
            cluster: dotenv.PUSHER_APP_CLUSTER,
            encrypted: true,
          });
          const channel = pusher.subscribe("chat");
          channel.bind("MessageSent", (data) => {
            getContacts();
          });
        }
      }
    }
  }, [user]);

  const styles = {
    title: {
      padding: "12.5px",
      fontSize: "19px",
      fontWeight: 500,
      fontFamily: "Poppins",
      marginLeft: "3px",
      lineHeight: "150%",
    },
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ width: "100vw", height: "100vh", borderRadius: "2px", p: 4 }}>
        <div style={{ display: "flex" }}>
          <div>
            <MainMenu
              unseenMessages={unseenMessages}
              role={role}
              showSurveys={showSurveys}
            />
          </div>
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                {props?.goBack ? (
                  <div>
                    <Button
                      startIcon={<img src={"/back.png"} alt={"Avatar"} />}
                      variant="contained"
                      sx={{ color: "blue", minWidth: "100px" }}
                      color="secondary"
                      component="span"
                      onClick={() => navigate(props.goBack)}
                    >
                      Volver
                    </Button>
                  </div>
                ) : null}
              </div>

              <div style={{ marginLeft: "15px", display: "flex" }}>
                {props?.firstTitles.map((firstTitle, index) => (
                  <Box
                    key={index}
                    sx={[
                      styles.title,
                      {
                        background: "#FCFCFC",
                        color: "#3988E3",
                        borderRadius: index == 0 ? "15px 0px 0px 15px" : "0px",
                      },
                    ]}
                  >
                    <Typography>{t(firstTitle)}</Typography>
                  </Box>
                ))}
                <Box
                  sx={[
                    styles.title,
                    {
                      background: "#3657D9",
                      color: "#FCFCFC",
                      borderRadius:
                        props.firstTitles.length == 0
                          ? "15px"
                          : "0px 15px 15px 0px",
                    },
                  ]}
                >
                  <Typography>{props?.secondTitle}</Typography>
                </Box>
              </div>
            </div>
            <Grid
              sx={{ marginTop: "20px", maxWidth: "100%" }}
              container
              spacing={0}
            >
              <div
                style={{
                  width: defaultWidth,
                  maxWidth: defaultWidth,
                  overflowX: "auto",
                }}
              >
                {role !== "" ? (
                  React.cloneElement(props.children ?? <></>, {
                    role: role,
                    viewedMessages: viewedMessages,
                  })
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          </div>
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "30px",
              right: "30px",
              minWidth: "150px",
              maxWidth: "400px",
            }}
          >
            <Button
              sx={{
                borderRadius: "15px !important",
                p: 1,
                right: "-20px",
                zIndex: 10,
                color: "#737589",
                nowrap: true,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              startIcon={<Avatar src={user?.user?.avatar?.url} />}
              color="secondary"
              variant="contained"
              onClick={() => navigate("/profile")}
            >
              {user?.user?.name}
            </Button>
            <Button
              sx={{
                width: 100,
                fontSize: 9,
                bg: "#3988E3",
                p: 1,
                lineHeight: 1,
              }}
              variant="contained"
              onClick={() => logout()}
            >
              <div spacing={0} sx={{ p: 0, m: 0, ml: 1 }}>
                <div xs={12}>
                  <img src={"/log-out.png"} alt={"Avatar"} />
                </div>
                <div xs={12}>{t("logout")}</div>
              </div>
            </Button>
          </div>
        </div>
      </Box>
    </MuiThemeProvider>
  );
};

export default Layout;
