import { Box } from "@mui/material";
import MembershipCard from "./MembershipCard";
import { useTranslation } from "react-i18next";
import BarChartMemberships from "./BarChartMemberships";

const ActualMemberships = (props) => {
  const { t } = useTranslation();
  const styles = {
    background: "#FCFCFC",
    borderRadius: "18px",
    padding: "40px 40px 20px",
    marginTop: "18px",
  };
  return (
    <Box>
      <Box mt={"20px"} display={"flex"} justifyContent={"space-between"}>
        <MembershipCard
          color="#FFF"
          background="#3DA6CC"
          icon="/new-users.png"
          title={t("new_users")}
          subtitle={t("new_users_month")}
          value={props.newMemberships}
        />
        <MembershipCard
          color="#FFF"
          background="#5EC360"
          icon="/active-users.png"
          title={t("active_users")}
          subtitle={t("active_users_month")}
          value={props.activeMemberships}
        />
        <MembershipCard
          color="#737589"
          background="#FFD952"
          icon="/inactive-users.png"
          title={t("inactive_users")}
          subtitle={t("inactive_users_month")}
          value={props.inactiveMemberships}
        />
      </Box>
      <Box sx={styles}>
        <BarChartMemberships
          chartImage={props.chartImage}
          title={t("user_activity")}
          subtitle={t("user_activity_subtitle")}
          footer={t("user_activity_footer")}
          newMemberships={props.newMemberships}
          activeMemberships={props.activeMemberships}
          inactiveMemberships={props.inactiveMemberships}
          labels={[t("new_users"), t("inactive_users"), t("active_users")]}
        />
      </Box>
    </Box>
  );
};
export default ActualMemberships;
