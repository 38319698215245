import * as React from "react";
import { Grid, InputLabel } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const MedicalCoverageData = (props) => {
  const { t } = useTranslation();
  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
      marginTop: "20px",
    },
    title: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
    labels: {
      whiteSpace: "pre-wrap",
    },
  };

  return (
    <Box sx={styles.container}>
      <InputLabel sx={styles.title}>{t("coverage_data")}</InputLabel>
      <Box sx={{ display: "flex" }}>
        <Grid item xs={5} width={"50%"}>
          <InputLabel sx={styles.labels}>
            <b>{t("coverage_data2")}: </b>
            {props.medicalCoverageData?.medical_insurance}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("plan")}: </b>
            {props.medicalCoverageData?.plan}
          </InputLabel>
        </Grid>
        <Grid item xs={7} ml={"56px"}>
          <InputLabel sx={styles.labels}>
            <b>{t("affiliate_number")}: </b>
            {props.medicalCoverageData?.affiliate_number}
          </InputLabel>
          <InputLabel sx={styles.labels}>
            <b>{t("ambulance_service")}: </b>
            {props.medicalCoverageData?.ambulance_service == 1 ? "Si" : "No"}
          </InputLabel>
        </Grid>
      </Box>
    </Box>
  );
};
export default MedicalCoverageData;
