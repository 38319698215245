import * as React from "react";
import moment from "moment";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PatientData from "./components/PatientData";
import ReportData from "./components/ReportData";
import ResumeInformationICC from "./components/ResumeInformationICC";
import Header from "./components/Header";
import LineChartsMeasurement from "../patients/components/LineChartsMeasurement";
import { useTranslation } from "react-i18next";
const ICCReport = (props) => {
  const { t } = useTranslation();
  const columns = [t("date_time"), t("priority"), t("type"), t("detail")];

  React.useEffect(() => {
    let pagesToSend = [
      document.getElementById("page1"),
      document.getElementById("page2"),
      document.getElementById("page3"),
    ];
    props.printPages(pagesToSend);
  }, []);

  const styles = {
    container: {
      margin: "0px",
    },
    table: {
      title: {
        color: "#1E1E1E",
        fontSize: "16px",
        fontWeight: "600",
        letterSpacing: "0.048px",
        margin: "12px 0",
      },
      headers: {
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "20px",
        color: "#737589",
        textAlign: "center",
        padding: "15px 5px",
      },
      cells: {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "20px",
        color: "#667085",
        textAlign: "center",
      },
    },
  };
  const boxStyle = {
    width: "100%",
    padding: "20px 30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "18px",
    margin: "10px 0px",
    title: {
      fontFamily: "Poppins",
      color: "#737589",
      fontSize: "22px",
      fontWeight: 600,
      textAlign: "center",
      marginBottom: "20px",
    },
  };

  return (
    <Box sx={styles.container}>
      <Box id="page1">
        <Header title={t("telemonitoring_report_chronic_heart_failure")} />
        <PatientData profile_data={props.data?.data?.info?.profile_data} />
        <ReportData
          date_from={props.data?.date_from}
          date_to={props.data?.date_to}
          type={props.data?.type}
          pathology={"ICC"}
          medicines={props.data?.data?.medications
            ?.map((med) => med.medicine.name)
            .join(", ")}
        />
        <ResumeInformationICC
          resume_information={props.data?.data?.resume_information}
        />
      </Box>
      <Box id="page2">
        <Box sx={[styles.boxTitles, { fontSize: "16px" }]} mt={"10px"}>
          {t("collected_data")}
        </Box>
        <Box sx={styles.boxTitles} mt={"10px"}>
          {t("graphic")}:
        </Box>
        <Box paddingRight={"60px"}>
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_how_i_feel")}</Box>
            <LineChartsMeasurement
              slug="how-i-feel"
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={props?.json?.graph_how_i_feel}
            />
          </Box>
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_weight")}</Box>
            <LineChartsMeasurement
              slug="weight"
              name={t("weight")}
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={props?.json?.graph_weight}
            />
          </Box>
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_blood_pressure")}</Box>
            <LineChartsMeasurement
              slug="systolic-blood-pressure"
              slug2="diastolic-blood-pressure"
              firstTitle="Sistólica"
              secondTitle="Diastólica"
              name="Presión arterial"
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={[
                props?.json?.graph_systolic_blood_pressure,
                props?.json?.graph_diastolic_blood_pressure,
              ]}
            />
          </Box>
        </Box>
      </Box>
      <Box id="page3">
        <Box paddingRight={"60px"}>
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_heart_rate")}</Box>
            <LineChartsMeasurement
              slug="heart-rate"
              name={t("heart_rate")}
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={props?.json?.graph_heart_rate}
            />
          </Box>
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_oxygen-saturation")}</Box>
            <LineChartsMeasurement
              slug="oxygen-saturation"
              name={t("oxygen_saturation")}
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={props?.json?.graph_oxygen_saturation}
            />
          </Box>
        </Box>
        <Box sx={styles.boxTitles} mt={"10px"}>
          {t("alarms_detail")}
        </Box>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "18px", mt: "10px" }}
        >
          <Table>
            <TableHead sx={{ position: "sticky", top: "0px", margin: "0 0" }}>
              <TableRow sx={{ background: "#F9FAFB" }}>
                {columns.map((col) => (
                  <TableCell key={col} sx={styles.table.headers}>
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data?.data?.resume_information?.alerts_resume?.alerts?.map(
                (alert) => (
                  <TableRow key={alert.id}>
                    <TableCell sx={styles.table.cells}>
                      {moment(alert.date).format("DD/M/yy HH:mm")}{" "}
                    </TableCell>
                    <TableCell sx={styles.table.cells}>
                      {alert.priority == 1 ? "Amarilla" : "Roja"}
                    </TableCell>
                    <TableCell sx={styles.table.cells}>{alert.slug}</TableCell>
                    <TableCell sx={styles.table.cells}>
                      {alert.detail}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default ICCReport;
