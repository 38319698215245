import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArticleIcon from "@mui/icons-material/Article";
import { List, ListItemIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircleIcon from "@mui/icons-material/Circle";

import { Grid } from "@mui/material";

const HTAHealthDashboardGlossaryPageProtocol = (props) => {
  const { t } = useTranslation();
  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "5%",
      marginTop: "20px",
    },
    parametro: {
      color: ("primary-dark", "#203482"),
      fontSize: "13px",
      fontFamily: "Poppins",
      fontWeight: "600",
    },
    cells: {
      fontSize: "12px",
      color: "#667085",
      fontFamily: "Poppins",
    },
    success: {
      color: "#5EC360",
    },
    warning: {
      color: "#F3BE34",
    },
    error: {
      color: "#D04444",
    },
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: ("grayscale-label", "#737589"),
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: "700",
      letterSpacing: "0.16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "12px",
      color: "#667085",
      fontFamily: "Poppins",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      borderBottom: "1px solid #EAECF0",
      background: ("--gray-50", "#F9FAFB"),
    },
    "&:nth-of-type(even)": {
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      borderBottom: "1px solid #EAECF0",
      background: ("--t-iza", "#F6F6F6"),
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const iconFile = (
    <ArticleIcon style={{ color: "#667085", fontSize: "inherit" }} />
  );
  const iconEye = (
    <RemoveRedEyeIcon style={{ color: "#667085", fontSize: "inherit" }} />
  );
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("parameter")}</StyledTableCell>
              <StyledTableCell>{t("detail")}</StyledTableCell>
              <StyledTableCell>{t("behavior")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>{t("name")}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography sx={styles.cells}>
                    {t("complete_name_patient")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
            <React.Fragment>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("antiquity")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography sx={styles.cells}>
                    {t("time_elapsed_enrollment")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
            <React.Fragment>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>{t("weight")}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography sx={styles.cells}>
                    {t("weight_patient")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("measurements_date")} <br />({t("from_to")})
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    {t("data_fist_last_measurement")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Grid container direction="column" spacing={1}>
                    <Grid item>{t("measurment_is")}</Grid>
                    <Grid item>{t("examp_sense_from")}</Grid>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("measurement_quantity")} <br />
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    {t("amount_measurements")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Grid container direction="column" spacing={1}>
                    <Grid item>{t("max_measurements")}</Grid>
                    <Grid item> {t("max_measurements2")}</Grid>
                    <Grid item> {t("exam")}:12/28</Grid>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("ta_average_day")} <br />
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    {t("show_pbd_average_pd")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_lest_than")}&nbsp;
                      <span style={styles.success}> 120 / 80</span>
                    </ListItemIcon>
                  </List>
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_yellow")}&nbsp;
                      <span style={styles.warning}> 140 / 80</span>
                    </ListItemIcon>
                  </List>
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_red")}&nbsp;
                      <span style={styles.error}> 190 / 100</span>
                    </ListItemIcon>
                  </List>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("ta_average_afernoon")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    {t("show_average_bp")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_lest_than")}&nbsp;
                      <span style={styles.success}>120 / 80</span>
                    </ListItemIcon>
                  </List>
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_yellow")}&nbsp;
                      <span style={styles.warning}>140 / 90</span>
                    </ListItemIcon>
                  </List>
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_red")}&nbsp;
                      <span style={styles.error}>185 / 100</span>
                    </ListItemIcon>
                  </List>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("ta_average_general")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    {t("four_daily_blood_pressure_measurements_average")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_lest_than")}&nbsp;
                      <span style={styles.success}>120 / 80</span>
                    </ListItemIcon>
                  </List>
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_yellow")}&nbsp;
                      <span style={styles.warning}>140 / 90</span>
                    </ListItemIcon>
                  </List>
                  <List>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <CircleIcon
                        sx={{
                          fontSize: "0.5rem",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                        }}
                      />
                      {t("average_red")}&nbsp;
                      <span style={styles.error}>185 / 100</span>
                    </ListItemIcon>
                  </List>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("hr_day_afternoon_average")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    {t("average_bp_afternoon_last_seven_days")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Grid container direction="column" spacing={1}>
                    <Grid item>{t("data_average_bpday_bpafternoon")}</Grid>
                    <Grid item>{t("exam")}:95 / 98.</Grid>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>
                    {t("fc_general_average")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    {t("show_average_fcg")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Grid container direction="column" spacing={1}>
                    <Grid item>{t("average_within_range")}</Grid>
                    <Grid item>{t("exam")}:95.</Grid>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography sx={styles.parametro}>{t("actions")}</Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    <Grid container direction="column" spacing={8}>
                      <Grid item xs={6}>
                        {t("see")} {iconEye} : {t("allows_you_see")}
                      </Grid>
                    </Grid>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify">
                  <Typography sx={styles.cells}>
                    <Grid container direction="column" spacing={8}>
                      <Grid item xs={6} align="justify">
                        {t("select_icon_ayes_redirect")}
                      </Grid>
                    </Grid>
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={styles.cells} align="justify">
        ( * ) {t("note")}
      </Typography>
    </div>
  );
};

export default HTAHealthDashboardGlossaryPageProtocol;
