import * as React from "react";
import {
  Grid,
  InputLabel,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import LabelRadioGroup from "./LabelRadioGroup";
import moment from "moment";
import { useTranslation } from "react-i18next";

const HealthBackgroundMedicalInformation = (props) => {
  const { t } = useTranslation();
  const [form, setForm] = React.useState({
    acv: props.healthBackground?.acv,
    acv_date: props.healthBackground?.acv_date,
    acv_sequel: props.healthBackground?.acv_sequel,
    myocardial_infarction: props.healthBackground?.myocardial_infarction,
    myocardial_infarction_date:
      props.healthBackground?.myocardial_infarction_date,
    myocardial_infarction_sequel:
      props.healthBackground?.myocardial_infarction_sequel,
    acute_edema: props.healthBackground?.acute_edema,
    ongoing_pregnancy: props.healthBackground?.ongoing_pregnancy,
    atrial_fibrillation: props.healthBackground?.atrial_fibrillation,
    anticoagulation: props.healthBackground?.anticoagulation,
    other_background_summary: props.healthBackground?.other_background_summary,
    other_background_summary_alert:
      props.healthBackground?.other_background_summary_alert,
  });
  const [warningMessage, setWarningMessage] = React.useState("");

  const [hover, setHover] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChange = (evt) => {
    let { name, value } = evt.target;
    if (name.includes("_date")) {
      value = moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
    }
    if (name === "other_background_summary") {
      if (value.length > 3000) {
        return; // Detener el procesamiento si el límite se supera
      } else if (value.length > 2998) {
        setWarningMessage(t("no_more_than"));
      } else {
        setWarningMessage("");
      }
    }
    setForm({ ...form, [name]: value });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    console.log(props);
    props.onChange("healthBackground", form);
  }, [form]);

  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
      marginTop: "20px",
    },
    title: {
      color: "#3988E3",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
    labels: {
      whiteSpace: "pre-wrap",
      color: "#869AE8",
      fontSize: "14px",
      fontWeight: "500",
    },
    inputFields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "0 20px",
      fontFamily: "Poppins",
      color: "#869AE8",
      fontSize: "14px",
      height: "46px",
      minWidth: "150px",
      width: "100%",
      marginTop: "5px",
      marginBottom: "8px",
    },
    fields: {
      borderRadius: "12px",
      backgroundColor: "#FCFCFC",
      border: "1px solid #869AE8",
      padding: "10px 12px",
      color: "#869AE8",
      width: "100%",
      resize: "none",
    },
    otherInf: {
      display: "flex",
      alignItems: "flex-start",
      gap: "16px",
    },
    textareaContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      width: "37%",
      minWidth: "383px",
      height: "82px",
      borderRadius: "12px",
      marginBottom: "16px",
    },
    labelInputAlarm: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      width: "100%",
      marginTop: "15%",
    },
    infoAl: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
      marginLeft: "3%",
      textAlign: "center",
      color: "black",
    },
    fluAL: {
      fontFamily: "Poppins",
      fontSize: "10px",
      fontWeight: "300",
      lineHeight: "18px",
      textAlign: "center",
      marginLeft: "3%",
      color: "grey",
    },
    hover: {
      visibility: "visible",
      width: "180px",
      backgroundColor: "#FCFCFC",
      color: "#fff",
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px",
      position: "absolute",
      zIndex: 1,
      bottom: "100%",
      left: "50%",
      transform: "translateX(-50%)",
      opacity: 1,
      transition: "opacity 0.3s",
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "3%",
      marginLeft: "5%",
      position: "relative",
      flexShrink: 0,
    },
    noMore: {
      color: "red",
      fontSize: "12px",
      marginTop: "15%",
    },
  };

  return (
    <Box sx={styles.container}>
      <InputLabel sx={styles.title}>{t("history_helth")}</InputLabel>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <LabelRadioGroup
            value={form.acv}
            align="end"
            name="acv"
            label={t("accident_ccp")}
            onChange={handleChange}
          />
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="previous-acv-date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(form.acv_date, "DD-MM-yyyy").format("YYYY-MM-DD")}
              id="previous-acv-date"
              name="acv_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
          <Box padding={0}>
            <InputLabel sx={styles.labels} htmlFor="previous-acv-sequel">
              {t("sequel")}
            </InputLabel>
            <textarea
              style={styles.fields}
              value={form.acv_sequel}
              id="previous-acv-sequel"
              rows={3}
              multiline
              name="acv_sequel"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <LabelRadioGroup
            align="end"
            value={form.myocardial_infarction}
            name="myocardial_infarction"
            label={t("previus_heart_attack")}
            onChange={handleChange}
          />
          <Box sx={{ alignSelf: "self-end" }}>
            <InputLabel sx={styles.labels} htmlFor="previous-infart-date">
              {t("date")}
            </InputLabel>
            <input
              type="date"
              value={moment(
                form.myocardial_infarction_date,
                "DD-MM-yyyy"
              ).format("YYYY-MM-DD")}
              id="previous-infart-date"
              name="myocardial_infarction_date"
              style={styles.inputFields}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <InputLabel sx={styles.labels} htmlFor="previous-infart-sequel">
              {t("sequel")}
            </InputLabel>
            <textarea
              style={styles.fields}
              value={form.myocardial_infarction_sequel}
              id="previous-infart-sequel"
              rows={3}
              multiline
              name="myocardial_infarction_sequel"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <LabelRadioGroup
          name="acute_edema"
          value={form.acute_edema}
          label={t("acute_lung_edema")}
          onChange={handleChange}
        />
        <LabelRadioGroup
          name="ongoing_pregnancy"
          value={form.ongoing_pregnancy}
          label={t("pregnancy_in_curs")}
          onChange={handleChange}
        />
        <LabelRadioGroup
          name="atrial_fibrillation"
          value={form.atrial_fibrillation}
          label={t("atrial_fibrillation")}
          onChange={handleChange}
        />
        <LabelRadioGroup
          name="anticoagulation"
          value={form.anticoagulation}
          label={t("anticoagulation")}
          onChange={handleChange}
        />
        <Box sx={styles.otherInf}>
          <Box sx={styles.textareaContainer}>
            <InputLabel sx={styles.labels} htmlFor="other_background_summary">
              {t("others")}
            </InputLabel>
            <textarea
              style={styles.fields}
              value={form.other_background_summary || ""}
              id="other_background_summary"
              rows={3}
              multiline
              name="other_background_summary"
              onChange={handleChange}
            />
          </Box>
          <Box>
            <p style={styles.noMore}>{warningMessage}</p>
          </Box>
          <Grid container direction="row" alignItems="center">
            <Grid item sx={styles.labelContainer}>
              <LabelRadioGroup
                name="other_background_summary_alert"
                value={form.other_background_summary_alert}
                label={"¿Activar Alarma?"}
                onChange={handleChange}
                sx={styles.labelInputAlarm}
              />
            </Grid>
            <Grid
              item
              sx={styles.imageContainer}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <img
                src="/exclamationMark.png"
                alt="Descripcion"
                onClick={handleClickOpen}
              />
              {hover && (
                <Box sx={styles.hover}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={styles.infoAl}>{t("info")}:</Typography>
                    <Typography sx={styles.fluAL}>{t("alarm_flow")}</Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          <Dialog
            open={open}
            onClick={handleClose}
            maxWidth="lg"
            fullWidth
            sx={{ "& .MuiDialog-paper": { width: "70%", height: "100%" } }}
          >
            <DialogContent
              sx={{ position: "relative", width: "100%", height: "100%" }}
            >
              <IconButton sx={{ position: "absolute", right: 8, top: 8 }}>
                <CloseIcon onClick={handleClose} />
              </IconButton>
              <img
                src="/flujo-alarma-hta.png"
                alt="Large Description"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};
export default HealthBackgroundMedicalInformation;
