import * as React from "react";
import AlertsTable from "../../components/AlertsTable";
import { useLocation, useParams } from 'react-router-dom';

const PatientAlertsIndexPage = (props) => {
  
  const { id } = useParams();
  const endpoint = `/institutions/v1/patients/${id}/alerts`;
  const location = useLocation();

  React.useEffect(()=>{
    if (location.state?.patient){
      props.setTitle(["Pacientes",location.state.patient],"");
    }
  },[])
  return (
    <div>
      <AlertsTable
        fromAlertsPage = {false}
        endpoint = {endpoint}
      ></AlertsTable>
    </div>
  );
};

export default PatientAlertsIndexPage;
