import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const styles = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    color: "#232323",
    fontSize: "17px",
    fontWeight: "600",
    fontFamily: "Poppins",
    textAlign: "center",
    margin: "0 10px",
  },
  subtitle: {
    color: "#737589",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Poppins",
    textAlign: "center",
  },
};
const Header = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={styles.flexCenter}>
        <img width={"203px"} src="/logo.png" />
      </Box>
      <Box sx={styles.title}>
        {props.title}
        <Box sx={styles.subtitle}>{t("study_summary")}</Box>
      </Box>
    </Box>
  );
};
export default Header;
