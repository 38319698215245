import {
  Grid,
  InputLabel,
  TextField,
  Select,
  Alert,
  MenuItem,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomizeSurveys = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "18px",
      backgroundColor: "white",
    },
    number: {
      width: "75px",
    },
    fields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
    },
    sendOptions: {
      mt: "30px",
      width: "100%",
      borderRadius: "12px",
      background: "rgba(134, 154, 232, 0.10)",
    },
    buttons: {
      display: "flex",
      padding: "12px 26px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      marginTop: "20px",
      borderRadius: "15px",
    },
    titleEac: {
      fontFamily: "Poppins",
      fontSize: "22px",
      fontWeight: "600",
      lineHeight: "26px",
      letterSpacing: "0.05em",
      color: "#3657D9",
      margin: "20px",
    },
  };

  const [error, setError] = React.useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [form, setForm] = React.useState({
    institution: "",
    medicalGroup: "",
    frequencyType: 1,
    frequencyNumber: "",
    answerPeriodType: 1,
    answerPeriodNumber: "",
    sendOption: 1,
    timeAfterEnrollmentType: 1,
    timeAfterEnrollmentNumber: 0,
  });

  React.useEffect(() => {
    if (props.form) {
      setForm((prevForm) => ({
        ...prevForm,
        frequencyType: props.form.frequencyType,
        frequencyNumber: props.form.frequencyNumber,
        answerPeriodType: props.form.answerPeriodType,
        answerPeriodNumber: props.form.answerPeriodNumber,
        sendOption: props.form.sendOption,
        timeAfterEnrollmentType: props.form.timeAfterEnrollmentType,
        timeAfterEnrollmentNumber: props.form.timeAfterEnrollmentNumber,
      }));
    }
  }, [props.form]);

  React.useEffect(() => {
    if (props.institutions.length > 0) {
      setForm((prevForm) => ({
        ...prevForm,
        institution: props.institutions[0].id,
      }));
    }
  }, [props.institutions]);

  React.useEffect(() => {
    if (props.medicalGroups.length > 0) {
      setForm((prevForm) => ({
        ...prevForm,
        medicalGroup: props.medicalGroups[0].id,
      }));
    }
  }, [props.medicalGroups]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "frequencyNumber" || name === "answerPeriodNumber") {
      const isValidInput =
        value === "" || (parseFloat(value) >= 0 && !isNaN(value));

      // Si es un número positivo o un campo vacío, actualiza el estado del formulario
      if (isValidInput) {
        setForm({ ...form, [name]: value });
      }
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
      if (event.target.name === "institution") {
        props.getMedicalGroups(event.target.value);
      }
    }
  };

  const checkForm = () => {
    const frequencyNumberDays = parseInt(
      form.frequencyType == 1
        ? form.frequencyNumber
        : form.frequencyType == 2
        ? form.frequencyNumber * 7
        : form.frequencyNumber * 30
    );
    const answerPeriodNumberDays = parseInt(
      form.answerPeriodType == 1
        ? form.answerPeriodNumber
        : form.answerPeriodType == 2
        ? form.answerPeriodNumber * 7
        : form.answerPeriodNumber * 30
    );
    let hasError = false;
    if (frequencyNumberDays < answerPeriodNumberDays) {
      setError(t("frequency_cannot"));
    } else {
      if (
        Number.isInteger(Number(form.frequencyNumber)) &&
        Number.isInteger(Number(form.answerPeriodNumber))
      ) {
        props.saveCustomization(form);
      } else {
        setError(t("frequency_period_mustbe"));
        hasError = true;
      }
    }
    if (formSubmitted && form.frequencyNumber === "") {
      setError(t("plase_select_frequency"));
      hasError = true;
    }
    if (formSubmitted && form.answerPeriodNumber === "") {
      setError(t("plase_select_period"));
      hasError = true;
    }
    if (!hasError) {
      setFormSubmitted(true);
    }
  };
  const isFrequencySelected = form.frequencyNumber !== "";
  const isAnswerPeriodNumber = form.answerPeriodNumber !== "";

  return (
    <Box sx={styles.container}>
      <Grid container sx={{ m: "30px" }}>
        <Grid item xs={3}>
          <InputLabel>{t("institution")}</InputLabel>
          <Select
            sx={styles.fields}
            id="select-institution"
            name="institution"
            value={form.institution}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {props.institutions.map((institution) => (
              <MenuItem key={institution.id} value={institution.id}>
                {institution.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <InputLabel>{t("medical_group")}</InputLabel>
          <Select
            sx={styles.fields}
            id="select-medical-group"
            name="medicalGroup"
            value={form.medicalGroup}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {props.medicalGroups.map((medicalGroup) => (
              <MenuItem key={medicalGroup.id} value={medicalGroup.id}>
                {medicalGroup.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <InputLabel>{t("shipping_frequency")}</InputLabel>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <TextField
              id="input-frequency"
              name="frequencyNumber"
              value={form.frequencyNumber}
              sx={[styles.number, styles.fields]}
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
            ></TextField>
            <Select
              sx={[styles.fields, { width: "130px" }]}
              id="select-frequency"
              name="frequencyType"
              value={form.frequencyType}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem key="1" value={1}>
                {t("day")}
              </MenuItem>
              <MenuItem key="2" value={2}>
                {t("weeks")}
              </MenuItem>
              <MenuItem key="3" value={3}>
                {t("month")}
              </MenuItem>
            </Select>
          </Box>
          {formSubmitted && !isFrequencySelected && (
            <Alert severity="error">{t("please_select_frequency")}</Alert>
          )}
        </Grid>
        <Grid item xs={3}>
          <InputLabel>{t("answer_time")}</InputLabel>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <TextField
              id="input-period"
              name="answerPeriodNumber"
              sx={[styles.number, styles.fields]}
              value={form.answerPeriodNumber}
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
            ></TextField>
            <Select
              sx={[styles.fields, { width: "130px" }]}
              id="select-period"
              name="answerPeriodType"
              value={form.answerPeriodType}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem key="1" value={1}>
                {t("day")}
              </MenuItem>
              <MenuItem key="2" value={2}>
                {t("weeks")}
              </MenuItem>
              <MenuItem key="3" value={3}>
                {t("month")}
              </MenuItem>
            </Select>
          </Box>
          {formSubmitted && !isAnswerPeriodNumber && (
            <Alert severity="error">{t("plese_select_time_answer")}</Alert>
          )}
        </Grid>
        <Box sx={styles.sendOptions}>
          <Grid item xs={12} sx={styles.title}>
            <Typography sx={styles.titleEac}>
              {t("first_time_send")}:
            </Typography>
          </Grid>
          <RadioGroup
            sx={{ m: "20px" }}
            value={form.sendOption}
            name="sendOption"
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={t("moment_enrollment")}
            />
            <Box display="flex" alignItems="center" sx={{ gap: "10px" }}>
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Enviar a los"
              />
              <TextField
                id="input-time-after-enrollment"
                name="timeAfterEnrollmentNumber"
                sx={[styles.number, styles.fields]}
                value={form.timeAfterEnrollmentNumber}
                disabled={form.sendOption == 1}
                onChange={(e) => {
                  handleChange(e);
                }}
              ></TextField>
              <Select
                sx={[styles.fields, { width: "130px" }]}
                id="select-time-after-enrollment"
                name="timeAfterEnrollmentType"
                disabled={form.sendOption == 1}
                value={form.timeAfterEnrollmentType}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <MenuItem key="1" value={1}>
                  {t("day")}
                </MenuItem>
                <MenuItem key="2" value={2}>
                  {t("weeks")}
                </MenuItem>
                <MenuItem key="3" value={3}>
                  {t("month")}
                </MenuItem>
              </Select>
              <span>{t("sense_enrollment")}</span>
            </Box>
          </RadioGroup>
        </Box>
        <Box width={"inherit"} mt={"10px"}>
          <InputLabel sx={{ color: "red", textAlign: "center" }}>
            {error}
          </InputLabel>
        </Box>
        <div
          align="right"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: "20px",
            width: "100%",
          }}
        >
          <Button
            sx={[
              styles.buttons,
              { color: "#FCFCFC", background: "#5EC360", width: "190px" },
            ]}
            color="primary"
            onClick={() => {
              checkForm();
            }}
            variant="contained"
          >
            {t("save")}
          </Button>
          <Button
            sx={[
              styles.buttons,
              { width: "148px", color: "#FCFCFC", background: "#D04444" },
            ]}
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </Grid>
    </Box>
  );
};
export default CustomizeSurveys;
