import * as React from "react";
import { useTranslation } from "react-i18next";
import { Table, TableContainer, TableHead,Paper, TableBody, TableRow, TableCell, Link } from '@mui/material';
import moment from "moment";
import { Box, IconButton } from "@mui/material";


const formatDate = (dateString) => {
  return moment(dateString).format("DD/MM/YYYY");
};
const EulaData = ({data}) => {  
  const { t } = useTranslation();
  const styles = {

    title: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
    subtitle:{
      color: "#3657D9",
      fontSize: "20px",
      lineHeight: "36px",
      fontWeight: "600",
      paddingBottom: "10px",

    },
    labels: {
      whiteSpace: "pre-wrap",
      overflowWrap: "anywhere",
    },
    cell: {
      color: "#3657D9",
    },
    iconButton: {
      color: "#3657D9",
    }
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "18px", mt: "10px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.title}>{t('document')}</TableCell>
            <TableCell sx={styles.subtitle}>{t('date')}</TableCell>
            <TableCell sx={styles.subtitle}>{t('download_link')}</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item, index) => (
              <TableRow key={index}>
                <TableCell  sx={styles.cell} >{item.license_name}</TableCell>
                <TableCell  sx={styles.cell}>{formatDate(item.date_register)}</TableCell>
                <TableCell sx={styles.cell}>
                <IconButton onClick={() => window.open(item.file_path, '_blank')} sx={styles.iconButton}>
                    <img src="/pdf.png" alt="PDF" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">{t('no_data')}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default EulaData;
