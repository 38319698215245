import { Box } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React from "react";

const BarChartMemberships = (props) => {
  const echartsRef = React.useRef(null);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (echartsRef) {
        props.chartImage(
          echartsRef?.current?.getEchartsInstance().getDataURL(),
          props.title
        );
      }
    }, 1000);
  }, [echartsRef])

  const styles = {
    title: {
      color: "#737589",
      fontSize: "18px",
      fontWeight: 600,
    },
    subtitle: {
      color: "#A5A7B6",
      fontSize: "14px",
      fontWeight: 400,
    },
    footer: {
      color: "#737589",
      fontSize: "12px",
      fontWeight: 400,
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
    },
    div: {
      height: "1px",
      width: "100%",
      background: "#A5A7B6",
    },
  };

  const option = {
    xAxis: {
      type: "category",
      data: props.labels,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [
          {
            value: props.newMemberships,
            itemStyle: {
              color: "#3DA6CC",
              borderRadius: [10, 10, 0, 0],
            },
          },
          {
            value: props.inactiveMemberships,
            itemStyle: {
              color: "#FFD952",
              borderRadius: [10, 10, 0, 0],
            },
          },
          {
            value: props.activeMemberships,
            itemStyle: {
              color: "#5EC360",
              borderRadius: [10, 10, 0, 0],
            },
          },
        ],
        type: "bar",
      },
    ],
  };

  return (
    <Box>
      <Box sx={styles.title}>{props.title}</Box>
      <Box sx={styles.subtitle}>{props.subtitle}</Box>
      <ReactECharts ref={echartsRef} option={option} />
      <Box sx={styles.div} />
      <Box sx={styles.footer}>
        <img style={{ marginRight: "5px" }} src="/clock.png" />
        {props.footer}
      </Box>
    </Box>
  );
};
export default BarChartMemberships;
