import { Box } from "@mui/material";
import ReactECharts from 'echarts-for-react';
import React from 'react';

const BarChartByDay = (props) => {
    const echartsRef = React.useRef(null);
    const [data, setData] = React.useState([]);
    const [series, setSeries] = React.useState([]);

    React.useEffect(() => {
        setData([
            props.items,
            ...props.labels.map(label => {
                if (props.data && props.data[label]) {
                    const red = props.data[label].red || 0;
                    const yellow = props.data[label].yellow || 0;
                    const completed = props.data[label].completed || 0;
                    if (props.priority) {
                        return [label, red, yellow];
                    } else {
                        return [label, completed];
                    }
                } else {
                    if (props.priority) {
                        return [label, 0, 0];
                    } else {
                        return [label, 0];
                    }
                }
            })
        ]);
    }, [props.data])

    React.useEffect(() => {
        setSeries(props.priority ?
            props.pathology == 2 ?
                [
                    { type: 'bar', color: props.colors[0], itemStyle: { borderRadius: [10, 10, 0, 0] } },
                ]
                :
                [
                    { type: 'bar', color: props.colors[0], itemStyle: { borderRadius: [10, 10, 0, 0] } },
                    { type: 'bar', color: props.colors[1], itemStyle: { borderRadius: [10, 10, 0, 0] } }
                ] : [
                { type: 'bar', color: props.colors[0], itemStyle: { borderRadius: [10, 10, 0, 0] } }
            ]);
    }, [data])

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (echartsRef) {
                props.chartImage(
                    echartsRef?.current?.getEchartsInstance().getDataURL(),
                    props.title
                );
            }
        }, 1000);
    }, [echartsRef])

    const styles = {
        title: {
            color: "#737589",
            fontSize: "18px",
            fontWeight: 600
        },
        subtitle: {
            color: "#A5A7B6",
            fontSize: "14px",
            fontWeight: 400
        },
        container: {
            background: "#FCFCFC",
            borderRadius: "18px",
            padding: "40px",
            marginTop: "18px"
        },
    }

    const option = {
        xAxis: {
            type: 'category',
        },
        legend: {
            bottom: 0,
        },
        tooltip: {},
        yAxis: {},
        dataset: {
            source: data
        },
        series: series
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.title}>{props.title}</Box>
            <Box sx={styles.subtitle}>{props.subtitle}</Box>
            <ReactECharts ref={echartsRef} option={option} />
        </Box>
    );
}
export default BarChartByDay;