import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const styles = {
  boxContainer: {
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.80)",
    padding: "10px",
  },
  boxTitles: {
    color: "#575565",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
  itemsTitle: {
    color: "#3657D9",
    fontSize: "11px",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
};

const ResumeInformationICC = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={[styles.boxTitles, { fontSize: "16px" }]} mt={"10px"}>
        {t("resum")}
      </Box>
      <Box sx={[styles.itemsTitle, { color: "#737589" }]} mt={"10px"}>
        {t("were_made")}
        {props.resume_information?.measurement?.count}{" "}
        {t("physiological_determinations")}{" "}
        {props.resume_information?.measurement?.percent}
        {t("percentage_total_values")} <br />
        {t("patients_present")}{" "}
        {props.resume_information?.measurement?.count_by_day}{" "}
        {t("days_with_load_least")}{" "}
        {props.resume_information?.measurement?.percent_by_day}
        {t("porcentaje_respect_total_period")} <br />
        {t("average_weight")} {props.resume_information?.weight_resume?.avg} kg.
        {t("maxim_was")} {props.resume_information?.weight_resume?.max} kg
        {t("and_minm_was")} {props.resume_information?.weight_resume?.min} kg.{" "}
        <br />
        {t("the_average_bp")} {props.resume_information?.blood_pressure?.avg}{" "}
        mmHg.
        {t("maxim_sistolyc")} {props.resume_information?.blood_pressure?.max}{" "}
        mmHg {t("and_max_diastolico")}{" "}
        {props.resume_information?.blood_pressure?.min} mmHg. <br />
        {t("average_heart_rate")} {props.resume_information?.heart_rate?.avg}{" "}
        lpm. {t("max_was")} {props.resume_information?.heart_rate?.max} lpm{" "}
        {t("the_min_was")} {props.resume_information?.heart_rate?.min} lpm.{" "}
        <br />
        {t("average_oxygen_saturation")}{" "}
        {props.resume_information?.oxygen_saturation?.avg}%. {t("max_was")}{" "}
        {props.resume_information?.oxygen_saturation?.max}% {t("the_min_was")}{" "}
        {props.resume_information?.oxygen_saturation?.min}%. <br />
        {t("patient_said_felt_good")}{" "}
        {props.resume_information?.how_i_feel?.avg_1}% {t("the_answers")},
        {t("feel_regular")}
        {props.resume_information?.how_i_feel?.avg_2}%{t("feel_bad")}{" "}
        {props.resume_information?.how_i_feel?.avg_3}%. <br />
        {t("patients_show")} {props.resume_information?.alerts_resume?.yellow}{" "}
        {t("yellow_alarms")} {props.resume_information?.alerts_resume?.red}{" "}
        {t("red_alarms_during")} <br />
      </Box>
    </Box>
  );
};
export default ResumeInformationICC;
