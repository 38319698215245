import * as React from "react";
import withMessages from "../../components/WithMessages";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { Box, InputLabel } from "@mui/material";
import CustomizeParameters from "../../components/CustomizeParameters";
import { useTranslation } from "react-i18next";

const CustomizeParametersPatientPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const { id } = useParams();
  const defaultWidth = window.innerWidth - 380 + "px";
  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.patient) {
      props.setTitle(["Pacientes", location.state.patient], "");
    }
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/patients/${id}/parameters`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setData(response.data);
          }
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally();
    };
    fetchData();
  }, []);

  const updateParams = (paramUpdate) => {
    const url = new URL(
      `/institutions/v1/patients/${id}/parameters`,
      dotenv.API_URL
    );
    axios
      .put(url.href, paramUpdate, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        props.showMessage(t("susses_update_parameter"), () => navigate(-1));
      })
      .catch((error) => console.log(error.response.data?.errors))
      .finally(() => {});
  };

  const styles = {
    patientName: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
    },
  };

  return (
    <div>
      {data ? (
        <Box>
          <Box>
            <InputLabel sx={styles.patientName}>{data.patient}</InputLabel>
            <CustomizeParameters
              data={{ params: data }}
              firstTitle={t("default_value")}
              secondTitle={t("custom_value")}
              updateParams={updateParams}
            ></CustomizeParameters>
          </Box>
        </Box>
      ) : error ? (
        <InputLabel sx={[styles.patientName, { textAlign: "center" }]}>
          {t("error_fetching_data")}
        </InputLabel>
      ) : (
        <InputLabel sx={[styles.patientName, { textAlign: "center" }]}>
          {t("loading")}
        </InputLabel>
      )}
      <br />
    </div>
  );
};

export default withMessages(CustomizeParametersPatientPage);
