import * as React from 'react';
import withMessages from '../../components/WithMessages';
import CustomizeParametersWithPathology from '../../components/CustomizeParametersWithPathology';
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';

const InstitutionCustomizeParams = (props) => {
    const { id } = useParams();
    const endpoint = `params/institutions/${id}/pathology`
    const location = useLocation();
    const { user } = useAuth();

    React.useEffect(()=>{
        if (location.state?.name){
            props.setTitle(["Instituciones",location.state.name],"");
        } else {
            props.setTitle(["Instituciones",user.institutions.find(inst => inst.id === id).name],"");
        }
    },[])

    return (
        ['superadmin', 'admin-vs', 'admin-institution'].some(
            r => props.role.includes(r)
          ) ?
            <CustomizeParametersWithPathology
                endpoint={endpoint}
                showMessage={props.showMessage}
                withPathology = {true}
            >
            </CustomizeParametersWithPathology>
            : <></>
    );
}

export default withMessages(InstitutionCustomizeParams);
