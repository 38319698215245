import React from "react";
import { Box, Button } from "@mui/material";

const QuestionSurveyCard = (props) => {
    const [opacity, setOpacity] = React.useState({});

    const styles = {
        card: {
            marginLeft: "auto",
            marginTop: "10px",
            width: "100%",
            height: "auto",
            background: "#F9F9F9",
            borderRadius: "15px",
            border: "0px",
            padding: "10px",
            body: {
                margin: "20px",
                title: {
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "150%",
                    letterSpacing: "-0.011em",
                    color: "#003d6a",
                    marginTop: "20px",
                },
                buttons: {
                    marginTop: "20px",
                    display: "flex",
                    width: "100%",
                    gap: "10px",
                    button: {
                        width: "100%",
                        gap: "6px",
                        borderRadius: "10px",
                        border:"0px",
                        height: "42px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "11px",
                        lineHeight: "15px",
                        color: "white",
                        background: "#003D6A"
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        const opacityData = {};
        props.question.data.forEach((option) => {
            opacityData[option.value] = props.answer === option.value ? '1' : '0.5';
        });
        setOpacity(opacityData);
    }, [props.question.data, props.answer]);


    return (
        <>
            <Box sx={styles.card}>
                <Box sx={styles.card.body}>
                    <Box sx={styles.card.body.title}>{ props.question.statement }</Box>
                    <Box sx={styles.card.body.buttons}>
                        {
                            props.question.data.map((option, index) =>(
                                <Button 
                                    sx={[styles.card.body.buttons.button,{opacity:opacity[option.value]}]}
                                    key={index}>
                                    {option.label}
                                </Button>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default QuestionSurveyCard;