import { Box, Button, Input } from "@mui/material";
import axios from "axios";
import React from "react";
import dotenv from "react-dotenv";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import ChagenResponse from "./ChageResponse";

const SelectMedicine = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [medicines, setMedicines] = React.useState([]);
  const [selectedMedicine, setSelectedMedicine] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [error, setError] = React.useState("");

  const handleSearch = (search) => {
    setSearchText(search.target.value);
  };

  const selectMedicine = (medicine) => {
    const existById = props.medicantionPlans?.some(
      (item) => item.medicine.id === medicine.id
    );

    const existMed = props.medicantionPlans?.some(
      (item) =>
        item.medicine.name === medicine.name &&
        item.medicine.presentation === medicine.presentation &&
        item.medicine.potency === medicine.potency &&
        item.medicine.monodrug === medicine.monodrug
    );
    if (existById || existMed) {
      setError(t("you_cant_add_same_drug"));
    } else {
      setSelectedMedicine(medicine);
      props.selectMedicine(medicine);
      setMedicines([]);
    }
  };

  React.useEffect(() => {
    if (searchText.length > 2) {
      searchMedicines();
    } else {
      if (searchText == "") {
        setMedicines([]);
      }
    }
  }, [searchText]);

  const searchMedicines = () => {
    const url = new URL("/institutions/v1/medicines/search", dotenv.API_URL);
    url.searchParams.set("search", searchText);
    url.searchParams.set("vademecum", props.vademecum);
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const response = ChagenResponse(res.data.data);

        setMedicines(response);
      });
  };

  const styles = {
    subtitle: {
      color: "#869AE8",
      fontSize: "14px",
      fontWeight: 500,
      marginTop: "30px",
      marginBottom: "10px",
    },
    select: {
      width: "100%",
      borderRadius: "12px",
      border: "1px solid #869AE8",
      background: "#FFF",
      color: "#869AE8",
      fontSize: "16px",
      fontWeight: 500,
      textAlign: "left",
    },
    medicinesBox: {
      width: "294px)px",

      padding: "10px 30px 10px 10px",
      gap: "10px",
      borderRadius: "10px",
      maxHeight: "200px",
      overflowY: "auto",
    },
  };
  const ErrorMessage = error || props.error;
  return (
    <>
      <Box sx={styles.subtitle}>{t("medication")}</Box>
      {ErrorMessage && <p style={{ color: "red" }}>{ErrorMessage}</p>}
      <Input
        disabled={!props.vademecum}
        disableUnderline={true}
        sx={[styles.select, { height: "56px", padding: "16px" }]}
        value={
          selectedMedicine
            ? `${selectedMedicine.name}  ${selectedMedicine.potency} ${selectedMedicine.presentation} (${selectedMedicine.monodrug})`
            : searchText
        }
        onClick={() => setSelectedMedicine(null)}
        onChange={handleSearch}
      />

      <Box sx={styles.medicinesBox}>
        {medicines.map((medicine) => (
          <Button
            onClick={() => selectMedicine(medicine)}
            key={medicine.id}
            sx={[styles.select, { height: "auto", padding: "16px" }]}
          >
            {(medicine.name ?? "") +
              " - " +
              (medicine.presentation ?? "") +
              " - " +
              (medicine.potency ?? "") +
              " " +
              (medicine.potency_unit ?? "") +
              " (" +
              (medicine.monodrug ?? "") +
              ")"}
          </Button>
        ))}
      </Box>
    </>
  );
};
export default SelectMedicine;
