import * as React from "react";
import { Avatar, InputLabel, Box, IconButton } from "@mui/material";
import InputEmoji from "react-input-emoji";
import Grid from "@mui/material/Unstable_Grid2";

import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import ChatUserPreview from "../../components/ChatUserPreview";
import ChatMessage from "../../components/ChatMessage";
import Moment from "moment";
import Pusher from "pusher-js";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChatIndexPage = (props) => {
  Moment.locale("es");
  const { user } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [chatUserId, setChatUserId] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [selectedRoom, setSelectedRoom] = React.useState(null);
  const [messages, setMessages] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [searchInput, setSearchInput] = React.useState("");
  const hiddenFileInput = React.useRef(null);
  const [messageToSend, setMessageToSend] = React.useState("");
  const [isAttached, setIsAttached] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const chatStyle = {
    chat: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "0px",
      height: "640px",
      border: "1px solid #EAECF0",
      boxShadow: "10px 15px 60px rgba(0, 0, 0, 0.04)",
      borderRadius: "18px",
      backgroundColor: "white",
    },
    list: {
      overflowY: "scroll",
    },
    boxInput: {
      margin: "12.5px 24px",
      border: "1px solid #869AE8",
      borderRadius: "12px",
      height: "55px",
      width: "90%",
      display: "flex",
      alignItems: "center",
      padding: "20px",
    },
    input: {
      border: "none",
      width: "100%",
      height: "50px",
      marginLeft: "10px",
      fontSize: "16px",
    },
    name: {
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "26px",
      color: "#203482",
    },
    chatInputMessage: {
      background: "rgba(255, 255, 255, 0.8)",
      border: "1px solid #E9E9E9",
      borderRadius: "8px",
    },
    attachedFile: {
      width: "10px",
      height: "10px",
      backgroundColor: "#3988E3",
      borderRadius: "5px",
      position: "absolute",
      right: "0px",
      top: "0px",
    },
  };

  React.useEffect(() => {
    if (id) {
      if (id != selectedRoom?.id) {
        changeRoomSelection();
      }
      const pusher = new Pusher(dotenv.PUSHER_APP_KEY, {
        cluster: dotenv.PUSHER_APP_CLUSTER,
        encrypted: true,
      });
      const channel = pusher.subscribe("chat");
      channel.bind("MessageSent", (data) => {
        if (data.message) {
          if (id == data.message?.ch_room_id) {
            fetchMessages(data.message.ch_room_id);
          }
          getContacts();
        }
      });
    }
  }, [id]);

  const changeRoomSelection = () => {
    let roomToSelect = contacts.find((contact) => contact.id == id);
    setSelectedRoom(roomToSelect);
    fetchMessages(id);
  };

  React.useEffect(() => {
    if (selectedRoom?.unseen) {
      makeSeen();
    }
  }, [selectedRoom]);

  const makeSeen = () => {
    const fetchData = async () => {
      const url = new URL("/chat/v1/chatify/api/makeSeen", dotenv.API_URL);
      axios
        .post(
          url.href,
          { ch_room_id: selectedRoom.id },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.user.chat_user_token,
            },
          }
        )
        .then(() => {
          props.viewedMessages();
        });
    };
    fetchData();
  };

  React.useEffect(() => {
    searchContacts();
  }, [searchInput]);

  React.useEffect(() => {
    if (contacts) {
      changeRoomSelection();
    }
  }, [contacts]);

  const getContacts = () => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = new URL("/chat/v1/chatify/api/getContacts", dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.user.chat_user_token,
          },
        })
        .then((response) => {
          setContacts(response.data.contacts);
          setChatUserId(response.data.chat_user_id);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  };

  const fetchMessages = (id) => {
    if (id) {
      const fetchData = async () => {
        const url = new URL(
          "/chat/v1/chatify/api/fetchMessages",
          dotenv.API_URL
        );

        axios
          .post(
            url.href,
            { chat_room_id: id },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + user.user.chat_user_token,
              },
            }
          )
          .then((response) => {
            setMessages(response.data.messages);
          });
      };
      fetchData();
    }
  };

  const searchContacts = () => {
    if (searchInput.length > 0) {
      const fetchData = setTimeout(() => {
        setIsLoading(true);
        const url = new URL(
          "/chat/v1/chatify/api/search?input=" + searchInput,
          dotenv.API_URL
        );
        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.user.chat_user_token,
            },
          })
          .then((response) => {
            setContacts(response.data.contacts);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 500);
      return () => clearTimeout(fetchData);
    } else {
      getContacts();
    }
  };

  const sendMessage = () => {
    if (file || messageToSend) {
      const fetchData = async () => {
        const url = new URL("/chat/v1/chatify/api/sendMessage", dotenv.API_URL);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("chat_room_id", selectedRoom?.id);
        formData.append("type", "user");
        formData.append("message", messageToSend);
        axios
          .post(url.href, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: "Bearer " + user.user.chat_user_token,
            },
          })
          .then((response) => {
            setMessageToSend("");
            setFile(null);
            setIsAttached(false);
            fetchMessages(selectedRoom?.id);
          });
      };
      fetchData();
    }
  };

  const attachFile = (event) => {
    hiddenFileInput.current.click();
  };

  const handleAttachChange = (event) => {
    setFile(event.target.files[0]);
    setIsAttached(true);
  };

  return (
    <div style={{ display: "flex" }}>
      <Grid item xs={4}>
        <Box sx={[chatStyle.chat, chatStyle.list]}>
          <Box sx={chatStyle.boxInput}>
            <img style={{ width: "20px" }} src="/chat_assets/search.png" />
            <input
              onChange={(event) => setSearchInput(event.target.value)}
              style={chatStyle.input}
              placeholder={t("searching")}
            ></input>
          </Box>
          {contacts?.length > 0 ? (
            contacts.map((room) => (
              <ChatUserPreview
                key={room.id}
                name={room.name}
                id={room.id}
                lastMessage={room.lastMessage?.body}
                time={
                  room.lastMessage?.created_at
                    ? Moment(room.lastMessage?.created_at).format(
                        "DD/MM/yyyy HH:mm:ss"
                      )
                    : ""
                }
                avatar={room.avatar}
                selected={room.id === selectedRoom?.id}
                unseen={room.unseen}
                onButtonClick={() => navigate("/chat/" + room.id)}
              />
            ))
          ) : isLoading ? (
            <p style={{ margin: "auto" }}>{t("loading")}</p>
          ) : (
            <p style={{ margin: "auto" }}>{t("no_contact_to_show")}</p>
          )}
        </Box>
      </Grid>
      <Grid item xs={8}>
        {selectedRoom ? (
          <Box
            sx={chatStyle.chat}
            minWidth={"700px"}
            maxWidth={"1200px"}
            ml={"20px"}
          >
            <Grid
              xs={12}
              sx={{
                margin: "13px 20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid xs={1}>
                <Avatar
                  alt="avatar"
                  src={selectedRoom?.avatar}
                  sx={{ width: "54px", height: "54px" }}
                />
              </Grid>
              <Grid xs={11} ml={"10px"}>
                <InputLabel sx={chatStyle.name}>{selectedRoom.name}</InputLabel>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "20px",
                height: "473px",
                overflowY: "scroll",
                flexDirection: "column-reverse",
              }}
            >
              {messages?.map((message) => (
                <ChatMessage
                  key={message.id}
                  attachmentFile={
                    new URL(
                      "/chat/v1/storage/attachments/" +
                        message.attachment?.file,
                      dotenv.API_URL
                    )
                  }
                  attachmentTitle={message.attachment?.title}
                  attachmentType={message.attachment?.type}
                  message={message.message}
                  fromName={message.from_name}
                  fromId={message.from_id}
                  userId={chatUserId}
                  datetime={message.timeAgo}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              mt={"5px"}
            >
              <InputEmoji
                value={messageToSend}
                onChange={setMessageToSend}
                cleanOnEnter
                onEnter={sendMessage}
                placeholder={t("write_message")}
              />
              <IconButton onClick={attachFile}>
                <img src={"/chat_assets/attach.png"} />
                <input
                  id="file_id"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleAttachChange}
                  style={{ display: "none" }}
                />
                {isAttached ? <Box sx={chatStyle.attachedFile}></Box> : <></>}
              </IconButton>
              <IconButton onClick={sendMessage}>
                <img src={"/chat_assets/send.png"} />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default ChatIndexPage;
