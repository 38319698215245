import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const TextFIeld = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#869AE8',
      borderRadius: 16,
      borderWidth: 1
    },
    '& input': {
      color: '#869AE8',
      backgroundColor: '#FCFCFC',
      borderRadius: 16,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      //placeholder color
    },
    '&::placeholder': {
      color: '#869AE8',
      opacity: 1,
    },
  },
});

export default TextFIeld