import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../hooks/useAuth.jsx";
import throttle from "lodash/throttle";
import TextField from '@mui/material/TextField';

const AddressTextField = (props) => {
  const [value, setValue] = React.useState(props.value ?? null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [error, setError] = React.useState(null);
  const { user } = useAuth();

  const fetchCities = React.useMemo(() =>
    throttle((request, callback) => {
      axios
        .get(request, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + user.token } })
        .then((response) => {
          callback(response.data);
        }).catch((error) => {
          setError(error.response.data.message);
        });
    }, 500),
    [user]
  );

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  React.useEffect(() => {
    setError(null);
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    
    if (inputValue.length >= 3) {
      fetchCities(`${dotenv.API_URL}/cities/search/${inputValue}`, (results) => {
        setOptions(results);
      });
    }
  }, [inputValue, fetchCities, user]);

  return (
    <Autocomplete
      id="address"
      getOptionLabel={(option) => option.name}
      sx={{
        'label + &': {
          marginTop: 2,
          color: '#869AE8',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#869AE8',
            borderRadius: 2,
          },
        },
        '& .MuiInputBase-root': {
          padding: 0.5,
          color: '#869AE8',
          backgroundColor: '#FCFCFC',
        }
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      name={props.name}
      filterSelectedOptions
      value={value ? { name: value } : null}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        props.onChange({ target: {'name': props.name, 'value': newValue?.name || null }});
        setValue(newValue?.name || null);
      }}
      renderInput={(params) => {
        return (
          <>
            {error ? <small>{error}</small> : null}
            <TextField  {...params} variant="outlined" fullWidth />
          </>
        )
      }}
    />
  )
};

export default AddressTextField;