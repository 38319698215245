import { Navigate } from "react-router-dom";
import  {useAuth}  from "../hooks/useAuth.jsx";
import { useLocation } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {

const location = useLocation();
const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" state={{ from: location }}  />;
  }
  return children;
};
