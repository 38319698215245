import * as React from 'react';
import { Avatar, Button, InputLabel} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';

const ChatUserPreview = (props) => {
  const userPreviewStyle = {
    chatPreview: {
      height: "90px",
      width: "100%",
      textAlign: "left"
    },
    userName: {
      fontWeight: "600",
      fontSize: "13px",
      lineHeight: "26px"
    },
    messagePreview: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
    },
    time: {
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      textWrap: "wrap",
      textAlign: "center"
    },
    unseen: {
      width: "15px",
      height: "15px",
      backgroundColor: "#3988E3",
      borderRadius: "7.5px",
      position: "absolute",
      right: "15px",
      top: "15px"
    }
  };
  const handleClick = () => {
    props.onButtonClick(props.id);
  };
  return (
    <Button sx={[userPreviewStyle.chatPreview, {backgroundColor: props.selected? '#3988E3': 'white'}]} onClick={handleClick}>
      <Box display={"flex"} width={"100%"} m={"25px 24px"}>
        <Grid xs={2} m={"auto 0"}>
          <Avatar alt="avatar" src={props.avatar} ml={"24px"} sx={{ width: '40px', height: '40px' }} />
        </Grid>
        <Grid xs={7} ml={"10px"}>
          <InputLabel sx={[userPreviewStyle.userName,{color: props.selected? 'white': "#203482"}]}>{props.name}</InputLabel>
          <InputLabel sx={[userPreviewStyle.messagePreview,{color: props.selected? 'white': "#A5A7B6"}]}>{props.lastMessage}</InputLabel>
        </Grid>
        <Grid xs={3} m={"auto"}>
          <InputLabel sx={[userPreviewStyle.time,{color: props.selected? 'white': "#575565"}]}>{props.time}</InputLabel>
          {
            props.unseen?
              <Box sx={userPreviewStyle.unseen}></Box>:<></>
          }
        </Grid>
      </Box>
    </Button>
  )
}
export default ChatUserPreview;
