import React from 'react';
import { useAuth } from '../../../hooks/useAuth';
import dotenv from 'react-dotenv';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';

const LineChartsMultipleMeasurement = (props) => {

  const { user } = useAuth();
  const [xLabels, setXLabels] = React.useState([]);
  const [yValues, setYValues] = React.useState([]);
  const [avgXLabels, setAvgXLabels] = React.useState([]);
  const [avgValues, setAvgValues] = React.useState([]);
  const [y2Values, setY2Values] = React.useState([]);
  const [xxLabels, setXxLabels] = React.useState([]);
  const [yyValues, setYyValues] = React.useState([]);
  const [yy2Values, setYy2Values] = React.useState([]);
  const [avgX2Labels, setAvgX2Labels] = React.useState([]);
  const [avg2Values, setAvg2Values] = React.useState([]);
  const [minValue, setMinValue] = React.useState(null);
  const [maxValue, setMaxValue] = React.useState(null);

  const echartsRef = React.useRef(null);

  const calcMin = (yValues, y2Values) => {
    let min1Value = Math.min(...yValues) - 10;
    let min2Value = Math.min(...y2Values) - 10;
    let minValue = Math.min(...[min1Value, min2Value]);
    return Math.floor(minValue / 5) * 5;
  }

  const calcMax = (yValues, y2Values) => {
    let max1Value = Math.max(...yValues) + 10;
    let max2Value = Math.max(...y2Values) + 10;
    let maxValue = Math.max(...[max1Value, max2Value]);
    return Math.floor(maxValue / 5) * 5;
  }

  React.useEffect(() => {
    if (!props.jsonComplete[0] && props.count) {
      getMeasurements(props.slug);
      getMeasurements(props.slug2, true);
    }
  }, [props.count, props.startDate, props.endDate]);

  // Si los datos vienen en un json de antes
  React.useEffect(() => {
    if (props?.jsonComplete[0]){
      if (props?.jsonComplete?.length > 1) {
        const mergedValues = mergeValuesByDate(props.jsonComplete[1]?.data1, props.jsonComplete[1]?.data2);
        setXxLabels(Object.keys(mergedValues));
        setYyValues(Object.values(mergedValues).map(({ y1 }) => y1));
        setYy2Values(Object.values(mergedValues).map(({ y2 }) => y2));
        setAvgX2Labels(props.jsonComplete[1]?.averages.map(item => item.x));
        setAvg2Values(props.jsonComplete[1]?.averages.map(item => item.y));
      }
      const mergedValues = mergeValuesByDate(props.jsonComplete[0]?.data1, props.jsonComplete[0]?.data2);
      setXLabels(Object.keys(mergedValues));
      setYValues(Object.values(mergedValues).map(({ y1 }) => y1));
      setY2Values(Object.values(mergedValues).map(({ y2 }) => y2));
      setAvgXLabels(props.jsonComplete[0]?.averages.map(item => item.x));
      setAvgValues(props.jsonComplete[0]?.averages.map(item => item.y));
    }
  }, [props.jsonComplete]);

  React.useEffect(() => {
    if (minValue != null && maxValue != null) {
      const timer = setTimeout(() => {
        props.chartImage(echartsRef.current.getEchartsInstance().getDataURL(), props.slug);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [maxValue, minValue])

  React.useEffect(() => {
    if (props.slug2) {
      let minValue = calcMin(yyValues, yy2Values);
      setMinValue(minValue);
    }
  }, [yy2Values]);

  React.useEffect(() => {
    let maxValue = calcMax(yValues, y2Values);
    setMaxValue(maxValue);
    if (!props.slug2) {
      let minValue = calcMin(yValues, y2Values)
      setMinValue(minValue);
    }
  }, [yValues]);

  const getMeasurements = (slug, second = false) => {
    const fetchData = async () => {
      const url = new URL(`/institutions/v1/users/${props.patient}/get-measurements-chart/${slug}`, dotenv.API_URL);
      url.searchParams.set("count", props?.count)
      if (props.startDate) {
        url.searchParams.set("startDate", props?.startDate)
      }
      if (props.endDate) {
        url.searchParams.set("endDate", props?.endDate)
      }
      axios.get(url.href, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then((response) => {
          if (!second) {
            const mergedValues = mergeValuesByDate(response.data.data1, response.data.data2);
            setXLabels(Object.keys(mergedValues));
            setYValues(Object.values(mergedValues).map(({ y1 }) => y1));
            setY2Values(Object.values(mergedValues).map(({ y2 }) => y2));
            setAvgXLabels(response.data.averages.map(item => item.x));
            setAvgValues(response.data.averages.map(item => item.y));
          } else {
            const mergedValues = mergeValuesByDate(response.data.data1, response.data.data2);
            setXxLabels(Object.keys(mergedValues));
            setYyValues(Object.values(mergedValues).map(({ y1 }) => y1));
            setYy2Values(Object.values(mergedValues).map(({ y2 }) => y2));
            setAvgX2Labels(response.data.averages.map(item => item.x));
            setAvg2Values(response.data.averages.map(item => item.y));
          }
        })
        .finally(() => {
        });
    };
    fetchData();
  }
  const parseDateDM = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return new Date(year, month - 1, day);
  };

const mergeValuesByDate = (values1, values2) => {
  const merged = {};

  values1.forEach(({ x, y }) => {
    merged[x] = { y1: y, y2: null };
  });

  values2.forEach(({ x, y }) => {
    if (!merged[x]) {
      merged[x] = { y1: null, y2: y };
    } else {
      merged[x].y2 = y;
    }
  });

  const sortedMerged = Object.entries(merged)
    .sort((a, b) => parseDateDM(a[0]) - parseDateDM(b[0]))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  return sortedMerged;
};


  let allLabels = xLabels.concat(xxLabels);
  let uniqueLabels = allLabels.filter((value, index, self) => self.indexOf(value) === index);
  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {},
    toolbox: {
      top: "5%",
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        dataView: { readOnly: false },
        magicType: { type: ['line', 'bar'] },
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: uniqueLabels
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
      },
      min: minValue ?? 0,
      max: maxValue ?? 150
    },
    series: [
      {
        name: props?.secondTitle,
        type: 'line',
        smooth: true,
        symbolSize: 5,
        data: xxLabels.map((value, index) => ({ name: value, value: yyValues[index] })),
        color: "#F9AD36",
        markPoint: {
          data: [
            { type: 'max', name: 'Máx' },
            { type: 'min', name: 'Mín' }
          ]
        },
      },
      {
        name: props?.second2Title,
        type: 'line',
        smooth: true,
        data: xxLabels.map((value, index) => ({ name: value, value: yy2Values[index] })),
        color: "#F97C36",
        markPoint: {
          data: [
            { type: 'max', name: 'Máx' },
            { type: 'min', name: 'Mín' }
          ]
        },
      },
      {
        name: props?.avg2Title,
        type: 'line',
        smooth: true,
        data: avgX2Labels.map((value, index) => ({ name: value, value: avg2Values[index] })),
        color: "#FFEA2B",
      },
      {
        name: props?.firstTitle,
        type: 'line',
        smooth: true,
        symbolSize: 5,
        data: xLabels.map((value, index) => ({ name: value, value: yValues[index] })),
        color: "#6885FF",
        markPoint: {
          data: [
            { type: 'max', name: 'Máx' },
            { type: 'min', name: 'Mín' }
          ]
        },
      },
      {
        name: props?.first2Title,
        type: 'line',
        smooth: true,
        data: xLabels.map((value, index) => ({ name: value, value: y2Values[index] })),
        color: "#68D2FF",
        markPoint: {
          data: [
            { type: 'max', name: 'Máx' },
            { type: 'min', name: 'Mín' }
          ]
        },
      },
      {
        name: props?.avgTitle,
        type: 'line',
        smooth: true,
        data: avgXLabels.map((value, index) => ({ name: value, value: avgValues[index] })),
        color: "#7DFF68",
      }
    ]
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactECharts ref={echartsRef} option={option} />
    </div>
  );
};

export default LineChartsMultipleMeasurement;
