const countries = [
  { code: "+93", name: "Afganistán" },
  { code: "+355", name: "Albania" },
  { code: "+49", name: "Alemania" },
  { code: "+376", name: "Andorra" },
  { code: "+244", name: "Angola" },
  { code: "+1 (264)", name: "Anguila" },
  { code: "+966", name: "Arabia Saudi" },
  { code: "+213", name: "Argelia" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+944", name: "Azerbaiyán" },
  { code: "+1(242)", name: "Bahamas" },
  { code: "+973", name: "Bahréin" },
  { code: "+880", name: "Bangladés" },
  { code: "+1(246)", name: "Barbados" },
  { code: "+32", name: "Bélgica" },
  { code: "+501", name: "Belice" },
  { code: "+229", name: "Benin" },
  { code: "+1(441)", name: "Bermudas" },
  { code: "+375", name: "Bielorrusia" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia y Herzegovina" },
  { code: "+267", name: "Botsuana" },
  { code: "+55", name: "Brasil" },
  { code: "+673", name: "Brunéi" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+975", name: "Bután" },
  { code: "+238", name: "Cabo Verde" },
  { code: "+855", name: "Camboya" },
  { code: "+237", name: "Camerún" },
  { code: "+1(011)", name: "Canadá" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+357", name: "Chipre" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoras" },
  { code: "+850 (99)", name: "Corea del Norte" },
  { code: "+82", name: "Corea del Sur" },
  { code: "+225", name: "Costa de Marfil" },
  { code: "+506", name: "Costa Rica" },
  { code: "+385", name: "Croacia" },
  { code: "+53", name: "Cuba" },
  { code: "+45", name: "Dinamarca" },
  { code: "+1(011)", name: "Dominica" },
  { code: "+593", name: "Ecuador " },
  { code: "+20", name: "Egipto" },
  { code: "+503", name: "El Salvador" },
  { code: "+971", name: "Emiratos Árabes Unidos" },
  { code: "+291", name: "Eritrea" },
  { code: "+421", name: "Eslovaquia" },
  { code: "+386", name: "Eslovenia" },
  { code: "+34", name: "España" },
  { code: "+1	(011)", name: "Estados Unidos" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Etiopía" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finlandia" },
  { code: "+63", name: "Filipinas" },
  { code: "+33", name: "Francia" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+233", name: "Ghana" },
  { code: "+1	(011)", name: "Granada" },
  { code: "+30", name: "Grecia" },
  { code: "+224", name: "Guinea" },
  { code: "+240", name: "Guinea Ecuatorial" },
  { code: "+245", name: "Guinea-Bisáu" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haití" },
  { code: "+504", name: "Honduras" },
  { code: "+36", name: "Hungría" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+964", name: "Irak" },
  { code: "+98", name: "Irán" },
  { code: "+353", name: "Irlanda" },
  { code: "+354", name: "Islandia" },
  { code: "+692 (011)", name: "Islas Marshall" },
  { code: "+677", name: "Islas Salomón" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italia" },
  { code: "+1	(011)", name: "Jamaica" },
  { code: "+81	(010)", name: "Japón" },
  { code: "+962", name: "Jordania" },
  { code: "+7	(8-10)", name: "Kazajistán" },
  { code: "+254", name: "Kenia" },
  { code: "+996", name: "Kirguistán" },
  { code: "+686", name: "Kiribati" },
  { code: "+965", name: "Kuwait" },
  { code: "+856", name: "Laos" },
  { code: "+266", name: "Lesoto" },
  { code: "+371", name: "Letonia" },
  { code: "+961", name: "Líbanos" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libia" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lituania" },
  { code: "+352", name: "Luxemburgo" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+60", name: "Malasia" },
  { code: "+960", name: "Malaui" },
  { code: "+960", name: "Maldivas" },
  { code: "+223", name: "Malí" },
  { code: "+356", name: "Malta" },
  { code: "+212", name: "Marruecos" },
  { code: "+230", name: "Mauricio" },
  { code: "+222", name: "Mauritania" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+233", name: "Moldavia" },
  { code: "+377", name: "Mónaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar" },
  { code: "+264", name: "Namibia" },
  { code: "+233", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+47", name: "Noruega" },
  { code: "+64", name: "Nueva Zelanda" },
  { code: "+968", name: "Omán" },
  { code: "+31", name: "Países Bajos" },
  { code: "+92", name: "Pakistán" },
  { code: "+680", name: "Palaos" },
  { code: "+507", name: "Panamá" },
  { code: "+675", name: "Papúa Nueva Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+48", name: "Polonia" },
  { code: "+351", name: "Portugal" },
  { code: "+44", name: "Reino Unido" },
  { code: "+236", name: "República Centroafricana" },
  { code: "+420", name: "República Checa" },
  { code: "+242", name: "República del Congo" },
  { code: "+243", name: "República Democratica del Congo" },
  { code: "+1	(011)", name: "República Dominicana" },
  { code: "+250", name: "Ruanda" },
  { code: "+40", name: "Rumania" },
  { code: "+7	(8-10)", name: "Rusia " },
  { code: "+685", name: "Samoa" },
  { code: "+1	(011)", name: "San Cristóbal y Nieves" },
  { code: "+239", name: "San Marino" },
  { code: "+233", name: "Santo Tomé y Príncipe" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leona" },
  { code: "+65", name: "Singapur" },
  { code: "+963", name: "Siria" },
  { code: "+252", name: "Somalia" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+268", name: "Suazilandia" },
  { code: "+27", name: "Sudáfrica" },
  { code: "+249", name: "Sudán" },
  { code: "+211", name: "Sudán del Sur" },
  { code: "+46", name: "Suecia" },
  { code: "+41", name: "Suiza" },
  { code: "+597", name: "Surinam" },
  { code: "+66", name: "Tailandia" },
  { code: "+255", name: "Tanzania" },
  { code: "+233", name: "Tayikistán" },
  { code: "+228", name: "Togo" },
  { code: "+676", name: "Tonga" },
  { code: "+993	(8-10)", name: "Turkmenistán" },
  { code: "+90", name: "Turquía" },
  { code: "+688", name: "Tuvalu" },
  { code: "+380", name: "Ucrania" },
  { code: "+256", name: "Uganda" },
  { code: "+598", name: "Uruguay" },
  { code: "+998	(8-10)", name: "Uzbekistán" },
  { code: "+678", name: "Vanuatu" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+967", name: "Yemen" },
  { code: "+253", name: "Yibuti" },
  { code: "+260", name: "Zambia" },

  { code: "+263", name: "Zimbabuemá" },
];

export default countries;
