import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SurveyGlossaryPage = (props) => {
  const { t } = useTranslation();
  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "5%",
      marginTop: "20px",
    },
    parametro: {
      color: ("primary-dark", "#203482"),
      fontSize: "13px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
    },
    textNote: {
      color: ("grayscale-body", "#575565"),
      fontSize: "16px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "25.6px",
    },
    styleHeaders: {
      color: ("grayscale-label", "#737589"),
      fontSize: "16px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "26px",
      letterSpacing: "0.16px",
    },
    siceHeaders: {
      height: "80px",
      padding: "12px 24px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceName: {
      height: "72px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      borderBottom: "1px solid #EAECF0",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceAnt: {
      height: "72px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      borderBottom: "1px solid #EAECF0",
      background: ("--t-iza", "#F6F6F6"),
    },
    sicePeso: {
      height: "118px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceTomasSe: {
      height: "162px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceTaPro: {
      height: "187px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceTap: {
      height: "187px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      borderBottom: "1px solid #EAECF0",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceFc: {
      height: "187px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceTaPm: {
      height: "199px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceFm: {
      height: "194px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceTapd: {
      height: "194px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceTapt: {
      height: "197px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceTapg: {
      height: "199px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceFcDt: {
      height: "222px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceFcPg: {
      height: "223px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceAc: {
      height: "229px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceNa: {
      height: "72px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceAnt2: {
      height: "72px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceAnt3: {
      height: "72px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    sicePe2: {
      flexDirection: "column",

      fontFamily: "Poppins",

      fontweight: "400",
      lineHeight: "20px",
      height: "118px",
      width: "406px",
      color: "#667085",
      fontSize: "12px",
      padding: "16px 30px",
      alignItems: "center",

      fontStyle: "normal",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceTas2: {
      height: "162px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceTaps: {
      height: "187px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceTasT: {
      display: "flex",
      width: "406px",
      flexDirection: "column",
      color: "#667085",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontweight: "400",
      lineHeight: "20px",
    },
    siceTaP2: {
      height: "187px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceFc2: {
      height: "170px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    siceTomasSe2: {
      color: "#667085",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
    },
    siceTaPm3: {
      height: "199px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
    siceFec: {
      height: "194px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--t-iza", "#F6F6F6"),
    },
    typoFc: {
      color: ("--primary-dark", "#203482"),
      fontSize: "13px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
    },
    siceFec1: {
      height: "193px",
      padding: "16px 30px",
      alignItems: "center",
      gap: "12px",
      alignSelf: "stretch",
      background: ("--gray-50", "#F9FAFB"),
    },
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: " #737589",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const iconEye = (
    <RemoveRedEyeIcon style={{ color: "#667085", fontSize: "inherit" }} />
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={[styles.styleHeaders, styles.siceHeaders, { width: "30%" }]}
              >
                {t("parameter")}
              </StyledTableCell>
              <StyledTableCell
                sx={[styles.styleHeaders, styles.siceHeaders]}
                align="left"
              >
                {t("detail")}
              </StyledTableCell>
              <StyledTableCell
                sx={[styles.styleHeaders, styles.siceHeaders]}
                align="left"
              >
                {t("behavior")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              <StyledTableRow>
                <StyledTableCell
                  sx={[styles.parametro, styles.siceName]}
                  align="left"
                >
                  <Typography sx={styles.typoFc}>{t("name")}</Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={styles.siceNa}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("complete_name_patient")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center" sx={styles.siceNa}>
                  -
                </StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
            <React.Fragment>
              <StyledTableRow>
                <StyledTableCell sx={[styles.parametro, styles.siceAnt]}>
                  <Typography sx={styles.typoFc}>{t("antiquity")}</Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={styles.siceAnt2}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("time_elapsed_since_enrollment")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center" sx={styles.siceAnt3}>
                  -
                </StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
            <React.Fragment>
              <StyledTableRow>
                <StyledTableCell sx={[styles.parametro, styles.sicePeso]}>
                  <Typography sx={styles.typoFc}>
                    {t("self_care_scale_heart_failure")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={styles.sicePe2}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("quantitative_level_heart_failure")}
                    <Link to="/ruta-de-destino">{t("see_detaill_survey")}</Link>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify" sx={styles.sicePe2}>
                  {t("behavior")}:<br></br>
                  {t("patient_not_recived_survey")}: ❌<br></br>
                  {t("patient_received_survey")}:⚠️
                  <br></br>
                  {t("if_diferent_null")}:<br></br>
                  {t("if_12_30")} 🟢 <br></br>
                  {t("if_is")}
                  {">"} 30 a 50 = {t("yellow_indicator")} 🟡
                  <br></br>
                  {t("if_is")}
                  {">"} 50 a 60 = {t("red_indicator")} 🔴
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell sx={[styles.parametro, styles.siceTomasSe]}>
                  <Typography sx={styles.typoFc}>
                    {t("test_minnesota")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify" sx={styles.siceTas2}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("surveys_utility")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify" sx={styles.siceTas2}>
                  <Typography sx={styles.siceTasT}>
                    {t("value_sacale")}
                    <br></br>
                    {t("behavior")}: <br></br>
                    {t("patient_not_recived_survey")}: ❌ <br></br>
                    {t("patient_received_survey")}:⚠️
                    <br></br>
                    {t("if_null_put_color_black")}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell sx={[styles.parametro, styles.siceTaps]}>
                  <Typography sx={styles.typoFc}>
                    {t("scale_adherence")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify" sx={styles.siceTaP2}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("survey_sear_qualify")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify" sx={styles.siceTaP2}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("scale_values_obtained_goes_from")}
                    <br></br>
                    {t("behavior")}
                    <br></br>
                    {t("patient_not_recived_survey")}: ❌ <br></br>
                    {t("patient_received_survey")}:⚠️ <br></br>
                    {t("if_diferent_null")}: <br></br> <br></br>
                    {t("if_is")} = 8 = {t("green_indicator")} 🟢 <br></br>
                    Si es 6 a {"<"} 8 {t("yellow_indicator")}
                    🟡<br></br>
                    Si es {">"}6 = {t("red_indicator")} 🔴<br></br>
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell sx={[styles.parametro, styles.siceAc]}>
                  <Typography sx={styles.typoFc}>{t("actions")}</Typography>
                </StyledTableCell>
                <StyledTableCell align="justify" sx={styles.siceFec}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("see")} {iconEye}:{" "}
                    {t("allows_see_history_response_patients")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="justify" sx={styles.siceFec}>
                  <Typography sx={styles.siceTomasSe2}>
                    {t("if_select_icon")} {iconEye} {t("sistem_redirect_to")}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </React.Fragment>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SurveyGlossaryPage;
