import { useAuth } from "../../../hooks/useAuth";
import { InputLabel } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import WithMessages from "../../../components/WithMessages";
import CustomizeSurveys from "../../../components/CustomizeSurvey";
import { useTranslation } from "react-i18next";

const CustomizeMultipleSurveysPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const selectedTemplates = location.state.selectedTemplates;
  const [institutions, setInstitutions] = React.useState([]);
  const [medicalGroups, setMedicalGroups] = React.useState([]);

  React.useEffect(() => {
    getInstitutions();
  }, []);

  const getInstitutions = () => {
    const fetchData = async () => {
      const url = new URL("/institutions/v1/institutions", dotenv.API_URL);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setInstitutions(response.data.data);
          getMedicalGroups(response.data.data[0].id);
        });
    };
    fetchData();
  };

  const getMedicalGroups = (institutionId) => {
    const fetchData = async () => {
      const url = new URL("/institutions/v1/medical-groups", dotenv.API_URL);
      url.searchParams.set("institution", `${institutionId}`);
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setMedicalGroups(response.data.data);
        });
    };
    fetchData();
  };

  const saveCustomization = (form) => {
    if (form.sendOption == 1) {
      form.timeAfterEnrollmentNumber = null;
      form.timeAfterEnrollmentType = null;
    }
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/surveys/configuration",
        dotenv.API_URL
      );
      axios
        .post(
          url.href,
          {
            institution: form.institution,
            medicalGroup: form.medicalGroup,
            sendFrequencyType: form.frequencyType,
            sendFrequency: form.frequencyNumber,
            responsePeriodType: form.answerPeriodType,
            responsePeriod: form.answerPeriodNumber,
            sendOption: form.sendOption,
            timeAfterEnrollmentType: form.timeAfterEnrollmentType,
            timeAfterEnrollment: form.timeAfterEnrollmentNumber,
            templates: selectedTemplates,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((response) => {
          props.showMessage(t("success_parameterize_surveys"));
          navigate(-1);
        })
        .catch((e) => {
          props.showMessage(
            t("an_error_has_occurred_try_later"),
            () => {},
            "error"
          );
        });
    };
    fetchData();
  };

  return (
    <div>
      {institutions.length > 0 && medicalGroups.length > 0 ? (
        <CustomizeSurveys
          institutions={institutions}
          medicalGroups={medicalGroups}
          saveCustomization={saveCustomization}
          getMedicalGroups={getMedicalGroups}
        />
      ) : (
        <InputLabel
          sx={{
            textAlign: "center",
            color: "#3657D9",
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "600",
          }}
        >
          {t("loading")}
        </InputLabel>
      )}
    </div>
  );
};
export default WithMessages(CustomizeMultipleSurveysPage);
