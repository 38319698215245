import * as React from "react";
import withMessages from "../../../components/WithMessages";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { Box, Button, InputLabel } from "@mui/material";
import axios from "axios";
import dotenv from "react-dotenv";
import UseModalityMedicalInformation from "../../../components/UseModalityMedicalInformation";
import HealthBackgroundMedicalInformation from "../../../components/HealthBackgroundMedicalInformation";
import HealthDataMedicalInformation from "../../../components/HealthDataMedicalInformation";
import { useTranslation } from "react-i18next";
const ModifyMedicalInformationHTA = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState({
    modality: "",
    healthData: [],
    healthBackground: [],
  });
  const styles = {
    column: {
      width: "50%",
      gap: "40px",
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      gap: "10px",
    },
    inputFields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "0 20px",
      fontFamily: "Poppins",
      color: "#869AE8",
      fontSize: "14px",
      height: "46px",
      minWidth: "150px",
      width: "100%",
      marginTop: "5px",
    },
    backgroundBox: {
      width: "100%",
      padding: "40px 40px",
      backgroundColor: "#FFFFFF",
      borderRadius: "18px",
      margin: "10px 0px",
    },
    buttons: {
      marginTop: "40px",
      borderRadius: "15px",
      color: "white",
    },
  };

  React.useEffect(() => {
    if (location.state?.patient) {
      props.setTitle(["Pacientes", location.state.patient], "");
    }
    getPatientData();
  }, []);

  const getPatientData = () => {
    setLoading(true);
    const url = new URL(
      "/institutions/v1/patients/" + id + "/health-hta",
      dotenv.API_URL
    );
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setForm({
          healthData: response.data.healthData,
          healthBackground: response.data.healthBackground,
          modality: response.data.modality.modality,
        });
      })
      .finally(() => setLoading(false));
  };

  const updateMedicalInformation = () => {
    const url = new URL(
      "/institutions/v1/patients/" + id + "/health-data-hta",
      dotenv.API_URL
    );
    axios
      .put(url.href, form, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        props.showMessage(t("updated_medical_info_correctly"), () =>
          navigate(-1)
        );
      })
      .catch((error) => {
        props.showMessage(t("you_must_select_one_modality"), () => {}, "error");
      });
  };

  const updateForm = (name, childForm) => {
    setForm({ ...form, [name]: childForm });
  };

  return (
    <div>
      {!loading ? (
        <Box sx={styles.backgroundBox}>
          <UseModalityMedicalInformation
            modality={form.modality}
            onChange={updateForm}
          />
          <HealthBackgroundMedicalInformation
            healthBackground={form.healthBackground}
            onChange={updateForm}
          />
          <HealthDataMedicalInformation
            healthData={form.healthData}
            onChange={updateForm}
          />
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={() => navigate(-1)}
              sx={[styles.buttons, { background: "#D04444", width: "232px" }]}
            >
              {t("cancel_modification")}
            </Button>
            <Button
              onClick={updateMedicalInformation}
              sx={[styles.buttons, { background: "#5EC360", width: "285px" }]}
            >
              {t("modify_medical_information")}
            </Button>
          </Box>
        </Box>
      ) : (
        <InputLabel
          sx={{
            textAlign: "center",
            color: "#3657D9",
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "600",
          }}
        >
          {t("loading")}
        </InputLabel>
      )}
    </div>
  );
};
export default withMessages(ModifyMedicalInformationHTA);
