import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
const PatientButtons = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const styles = {
    buttons: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
    },
    button: {
      marginTop: "15px",
      marginBottom: "15px",
      borderRadius: "15px",
      background: "var(--primary-default, #3657D9)",
      color: "white",
    },
  };

  return (
    <Box sx={styles.buttons}>
      <Button
        sx={styles.button}
        onClick={() =>
          navigate("./alerts", {
            state: {
              patient: props.name,
            },
          })
        }
      >
        {t("see_alarms")}
      </Button>
      <Button
        sx={styles.button}
        onClick={() =>
          navigate("./reports", {
            state: {
              patient: props.name,
              pathology: props.pathology
            },
          })
        }
      >
        {t("see_reports")}
      </Button>
      { props.pathology != 'hta' ?
        <Button
          sx={styles.button}
          onClick={() => navigate("./surveys", {
            state: {
              patient: props.name
            }
          })}>
          {t("see_surveys")}
        </Button>:<></>
      }
      {["superadmin", "doctor"].some(
        r => props.role.includes(r)
      ) ? (
        <Button
          sx={styles.button}
          onClick={() =>
            navigate("./customize-parameters", {
              state: {
                patient: props.name,
              },
            })
          }
        >
          {t("customize_parameters")}
        </Button>
      ) : (
        <></>
      )}
      <Button
        sx={styles.button}
        onClick={() =>
          navigate("./medical-information-" + props.pathology, {
            state: {
              patient: props.name,
            },
          })
        }
      >
        {t("modify_medical_data")}
      </Button>
      <Button
        sx={styles.button}
        onClick={() =>
          navigate("./profile", {
            state: {
              patient: props.name,
            },
          })
        }
      >
        {t("data_profile")}
      </Button>
    </Box>
  );
};
export default PatientButtons;
