import { Box } from "@mui/material";
import ColorBarMeasurement from "./ColorBarMeasurement";
import { useTranslation } from "react-i18next";

const styles = {
  boxTitles: {
    color: "#575565",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
  subtitle: {
    color: "#575565",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
  text: {
    color: "#575565",
    fontSize: "11px",
    fontWeight: "500",
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
};

const Morisky = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={[styles.boxTitles, { fontSize: "16px" }]} mt={"10px"}>
        {t("contributing_factors_evaluated")}
      </Box>
      <Box sx={styles.subtitle}>
        {t("patient_complete_full_scale")} {props.morisky?.score} {t("points")}
      </Box>
      <Box sx={styles.subtitle} mt={"10px"}>
        • {t("interpretation_full_scale")}
      </Box>
      <Box
        sx={styles.text}
        mt={"10px"}
        display={"flex"}
        justifyContent={"space-evenly"}
      >
        <Box> {t("yes")} = 0</Box>
        <Box> {t("no")} = 1</Box>
        <Box>|</Box>
        <Box>= 4 {t("ideal")}</Box>
        <Box>2 – 3 {t("half")}</Box>
        <Box>{"< = 1 bajo"}</Box>
      </Box>
      <Box sx={styles.subtitle} mt={"10px"}>
        • {t("answers")}
        <Box sx={styles.text} ml={"20px"}>
          • {t("forgot_take_bp")}
          <br />
          {props.morisky?.answers?.forgotten_medicine ? "SI" : "NO"}
          <br />• {t("forgot_take_medication")} <br />
          {props.morisky?.answers?.taken_medicine_on_time ? "SI" : "NO"}
          <br />• {t("stop_take_medication")}
          <br />
          {props.morisky?.answers?.medicine_discontinued_well ? "SI" : "NO"}
          <br />• {t("stop_take_medication_if_you_fell_bad")}
          <br />
          {props.morisky?.answers?.medicine_discontinued_unwell ? "SI" : "NO"}
          <br />
        </Box>
      </Box>
      <ColorBarMeasurement
        title="Escala Morisky-Green-Levine"
        centerColor={false}
        labels={["0", "1", "2", "3", "4"]}
        colors={["#D13A3A", "#FFE663", "#63DB76"]}
        value={props.morisky?.score}
        percentage={props.morisky?.score / 4}
      />
      <Box sx={styles.subtitle} mt={"-20px"}>
        {t("tree_were_mede_answers")}
      </Box>
      <Box sx={styles.text} ml={"20px"}>
        {t("ate_food_salty")}{" "}
        {props.morisky?.answers?.ate_salty_foods ? t("yes") : t("no")}. <br />
        {t("put_sat_infood")}{" "}
        {props.morisky?.answers?.added_salt_to_food ? t("yes") : t("no")}.{" "}
        <br />
        {t("druk_tomuch_alcohol")}{" "}
        {props.morisky?.answers?.drank_alcohol ? t("yes") : t("no")}.
      </Box>
      <Box sx={styles.subtitle}>{t("answer_about_fa")}</Box>
      <Box sx={styles.text} ml={"20px"}>
        {t("how_many_times")}
        <br /> {props.morisky?.answers?.physical_activity_times} {t("times")}={" "}
        {props.morisky?.answers?.physical_activity_minutes} {t("min")}
      </Box>
      <Box sx={styles.text} mt={"10px"}>
        {t("ideal_prevention_150min")} <br />
        {props.morisky?.answers?.physical_activity_ideal
          ? t("this_aspect_seems")
          : t("seems_oportunity_bebetter")}
      </Box>
    </Box>
  );
};
export default Morisky;
