import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const styles = {
  boxContainer: {
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.80)",
    padding: "10px",
  },
  boxTitles: {
    color: "#575565",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
  itemsTitle: {
    color: "#3657D9",
    fontSize: "11px",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
};

const ReportData = (props) => {
  const { t } = useTranslation();
  return (
    <Box sx={styles.boxContainer} mt={"10px"}>
      <Box sx={styles.boxTitles}>{t("data_study")}</Box>
      <Box display={"flex"} mt={"10px"} justifyContent={"space-between"}>
        <Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("start_report")}{" "}
            <span style={{ color: "#869AE8" }}> {props.date_from}</span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9", mt: "10px" }]}>
            {t("period")}{" "}
            <span style={{ color: "#869AE8" }}> {props.type}</span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("movil_resport")}{" "}
            <span style={{ color: "#869AE8" }}> {props.pathology}</span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("drugs")}:{" "}
            <span style={{ color: "#869AE8" }}> {props.medicines}</span>
          </Box>
        </Box>
        <Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("report_end")}{" "}
            <span style={{ color: "#869AE8" }}> {props.date_to}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ReportData;
