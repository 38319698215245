import * as React from "react";
import MaterialTable from "../../components/MaterialTable";
import {
  IconButton,
  Box,
  InputLabel,
  Button,
  Chip,
  AppBar,
  Typography,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ExportToCsv } from "export-to-csv";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";
import SelectPathologyWithModalityAndMedicalGroup from "../../components/SelectPathologyWithModalityAndMedicalGroup";

const HealthDashboardIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  props = { ...props, user };
  const [data, setData] = React.useState([]);
  const [dataToExport, setDataToExport] = React.useState([]);
  const [selectedPathology, setSelectedPathology] = React.useState(null);
  const [selectedMedicalGroup, setSelectedMedicalGroup] = React.useState(null);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingExport, setIsLoadingExport] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [selectedColumns, setSelectedColumns] = React.useState([]);

  const csvOptions = {
    fieldSeparator: ";",
    filename: "Tablero de salud",
    decimalSeparator: ",",
    useBom: true,
    useKeysAsHeaders: false,
    showLabels: true,
    headers: selectedColumns.map((c) => c.header).slice(0, -1),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const columnsICC = React.useMemo(
    () => [
      {
        id: "fullname",
        accessorKey: "fullname",
        header: t("name"),
        key: "fullname",
        size: 100,
        Cell: (cell) => (
          <Box sx={{ fontSize: "13px", fontWeight: 600, color: "#203482" }}>
            {cell.row.original.fullname}
          </Box>
        ),
      },
      {
        id: "antiquity",
        accessorKey: "antiquity",
        header: t("antiquity"),
        key: "antiquity",
        size: 80,
      },
      {
        id: "FEVI",
        accessorKey: "FEVI.value",
        header: "FEVI",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        Cell: (cell) => (
          <Chip
            sx={
              cell.row.original?.FEVI?.value > 40
                ? styles.correctChip
                : styles.yellowChip
            }
            label={
              cell.row.original?.FEVI?.value != null
                ? cell.row.original?.FEVI?.value + "%"
                : "--"
            }
          />
        ),
      },
      {
        id: "drugs",
        accessorKey: "drugs",
        header: t("amount_drugs"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 100,
        Cell: (cell) => (
          <Box
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#203482",
              marginLeft: "15%",
            }}
          >
            {cell.row.original.drugs}
          </Box>
        ),
      },
      {
        id: "weekly_adherence",
        accessorKey: "weekly_adherence",
        header: t("weekly_adherence"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: (cell) => (
          <Chip
            sx={[
              cell.row.original.weekly_adherence < 40
                ? styles.wrongChip
                : cell.row.original.weekly_adherence <= 70
                  ? styles.yellowChip
                  : styles.correctChip,
              { marginLeft: "25%" },
            ]}
            label={
              cell.row.original.weekly_adherence != null
                ? cell.row.original.weekly_adherence + "%"
                : "--"
            }
          />
        ),
      },
      {
        id: "last_data_loaded",
        accessorKey: "last_data_loaded",
        header: t("last_load"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableSorting: false,
        size: 80,
      },
      {
        id: "hr_today",
        accessorKey: "hr_today",
        header: t("fc_today"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        Cell: (cell) => (
          <Chip
            sx={
              cell.row.original.hr_today?.normal
                ? styles.correctChip
                : styles.wrongChip
            }
            label={
              cell.row.original.hr_today
                ? cell.row.original.hr_today.value
                : "-"
            }
          />
        ),
      },
      {
        id: "bp_weekly_average",
        accessorKey: "bp_weekly_average",
        header: t("ta_weekly_average"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: (cell) => (
          <Chip
            sx={[
              cell.row.original.bp_weekly_average?.normal
                ? styles.correctChip
                : styles.wrongChip,
              { marginLeft: "25%" },
            ]}
            label={cell.row.original.bp_weekly_average?.value}
          />
        ),
      },
      {
        id: "face_today",
        accessorKey: "face_today",
        header: t("face_today"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: (cell) =>
          cell.row.original.face_today ? (
            <img
              style={{ marginLeft: "10%" }}
              src={`/face${cell.row.original?.face_today}.png`}
            />
          ) : (
            <></>
          ),
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableSorting: false,
        Cell: (cell) => (
          <IconButton
            onClick={() => navigate(`/patients/${cell.row.original.id}`)}
            aria-label="view"
          >
            <img src="/view.png" alt="view" />
          </IconButton>
        ),
      },
    ],
    [navigate, t]
  );
  const columnsHTAProtocol = React.useMemo(
    () => [
      {
        id: "fullname",
        accessorKey: "fullname",
        header: t("name"),
        key: "fullname",
        size: 100,
        Cell: (cell) => (
          <Box sx={{ fontSize: "13px", fontWeight: 600, color: "#203482" }}>
            {cell.row.original.fullname}
          </Box>
        ),
      },
      {
        id: "antiquity",
        accessorKey: "antiquity",
        header: t("antiquity"),
        key: "antiquity",
        size: 50,
      },
      {
        id: "weight",
        accessorKey: "weight",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        header: t("weight"),
        size: 10,
        Cell: (cell) => (
          <Box sx={{ marginLeft: "15%" }}>{cell.row.original.weight}</Box>
        ),
      },
      {
        id: "measurement_dates",
        accessorKey: "bp_values.measurement_dates.value",
        header: "Fecha mediciones (desde / hasta)",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: (cell) => (
          <Box sx={{ marginLeft: "25%" }}>
            {cell.row.original.bp_values?.measurement_dates?.value}
          </Box>
        ),
      },
      {
        id: "count_measurements",
        accessorKey: "count_measurements",
        header: t("measurement_quantity"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "bp_morning_average",
        accessorKey: "bp_values.bp_morning_average",
        header: t("ta_average_day"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: (cell) => (
          <Chip
            sx={[
              cell.row.original.bp_values?.bp_weekly_average?.normal
                ? styles.correctChip
                : styles.wrongChip,
              { marginLeft: "15%" },
            ]}
            label={
              cell.row.original.bp_values?.bp_morning_average?.value ?? "--"
            }
          />
        ),
      },
      {
        id: "bp_afternoon_average",
        accessorKey: "bp_values.bp_afternoon_average",
        header: t("ta_average_afernoon"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: (cell) => (
          <Chip
            sx={[
              cell.row.original.bp_values?.bp_weekly_average?.normal
                ? styles.correctChip
                : styles.wrongChip,
              { marginLeft: "15%" },
            ]}
            label={
              cell.row.original.bp_values?.bp_afternoon_average?.value ?? "--"
            }
          />
        ),
      },
      {
        id: "bp_general_average",
        accessorKey: "bp_values.bp_weekly_average",
        header: t("ta_average_general"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        Cell: (cell) => (
          <Chip
            sx={[
              cell.row.original.bp_values?.bp_weekly_average?.normal
                ? styles.correctChip
                : styles.wrongChip,
              { marginLeft: "15%" },
            ]}
            label={
              cell.row.original.bp_values?.bp_weekly_average?.value ?? "--"
            }
          />
        ),
      },
      {
        id: "hr_morning_afternoon",
        accessorKey: "hr_values.hr_morning_afternoon",
        header: t("hr_morning_afternoon"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "hr_general_average",
        accessorKey: "hr_values.hr_general_average",
        header: t("hr_general_average"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "act",
        accessorKey: "act",
        header: t("actions"),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableSorting: false,
        Cell: (cell) => (
          <IconButton
            onClick={() => navigate(`/patients/${cell.row.original.id}`)}
            aria-label="view"
          >
            <img src="/view.png" alt="view" />
          </IconButton>
        ),
      },
    ],
    [navigate, t]
  );
  const columnsHTAMaintainance = React.useMemo(
    () => [
      {
        id: "fullname",
        accessorKey: "fullname",
        header: t("name"),
        key: "fullname",
        size: 100,
        Cell: (cell) => (
          <Box sx={{ fontSize: "13px", fontWeight: 600, color: "#203482" }}>
            {cell.row.original.fullname}
          </Box>
        ),
      },
      {
        id: "antiquity",
        accessorKey: "antiquity",
        header: t("antiquity"),
        key: "antiquity",
        size: 50,
      },
      {
        id: "weight",
        accessorKey: "weight",
        enableColumnActions: false,
        enableColumnFilter: false,
        header: t("weight"),
        size: 10,
        Cell: (cell) => (
          <Box sx={{ marginLeft: "15%" }}>{cell.row.original.weight}</Box>
        ),
      },
      {
        id: "count_measurements",
        accessorKey: "count_measurements",
        header: t("measurement_quantity"),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "bp_average",
        accessorKey: "bp_values.bp_average",
        header: t("bp_average"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "hr_average",
        accessorKey: "hr_values.hr_average",
        header: t("hr_average"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "bp_max",
        accessorKey: "bp_values.bp_max",
        header: t("bp_max"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "hr_max",
        accessorKey: "hr_values.hr_max",
        header: t("hr_max"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "bp_values.sys_out",
        accessorKey: "bp_values.sys_out",
        header: t("bp_sys_out"),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "bp_values.dias_out",
        accessorKey: "bp_values.dias_out",
        header: t("bp_dias_out"),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 80,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        id: "acti",
        accessorKey: "act",
        header: t("actions"),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableSorting: false,
        Cell: (cell) => (
          <IconButton
            onClick={() => navigate(`/patients/${cell.row.original.id}`)}
            aria-label="view"
          >
            <img src="/view.png" alt="view" />
          </IconButton>
        ),
      },
    ],
    [navigate, t]
  );

  React.useEffect(() => {
    changeColumn(selectedPathology);
  }, [selectedPathology, t]);

  let source;

  const updateTable = (
    pathology,
    medicalGroupId = selectedMedicalGroup,
    withLoading = true,
    exportCSV = false
  ) => {
    if (pathology != null && medicalGroupId != null) {
      // Cancela la petición anterior si existe
      if (source) {
        source.cancel("Operación cancelada por nueva petición");
      }
      // Crea un nuevo CancelToken
      source = axios.CancelToken.source();

      const fetchData = async () => {
        if (!exportCSV) {
          if (withLoading) {
            setIsLoading(true);
          }
          setIsRefetching(true);
          setIsError(false);
        } else {
          setIsLoadingExport(true);
        }

        const url = new URL(
          "/institutions/v1/memberships/health-data-table",
          dotenv.API_URL
        );
        url.searchParams.set("page", `${pagination.pageIndex + 1}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
        url.searchParams.set("pathologyId", pathology.pathology);
        url.searchParams.set("modalityId", pathology.modality);
        url.searchParams.set("medicalGroupId", medicalGroupId);
        url.searchParams.set("exportCSV", exportCSV);

        axios
          .get(url.href, {
            cancelToken: source.token,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            if (!exportCSV) {
              setData(response.data);
              setRowCount(response.data.total);
            } else {
              setDataToExport(response.data);
            }
            setIsError(false);
          })
          .catch((thrown) => {
            if (axios.isCancel(thrown)) {
            } else {
              setIsError(true);
            }
          })
          .finally(() => {
            setIsRefetching(false);
            setIsLoading(false);
            setIsLoadingExport(false);
          })
      };
      fetchData();
    }
  };

  React.useEffect(() => {
    if (selectedPathology !== "") {
      updateTable(selectedPathology, selectedMedicalGroup);
    }
    // Cancela la petición cuando el componente se desmonta
    return () => {
      if (source) {
        source.cancel("Componente desmontado");
      }
    };
  }, [selectedMedicalGroup]);

  React.useEffect(() => {
    if (selectedPathology !== "") {
      updateTable(selectedPathology, selectedMedicalGroup, false);
    }
    // Cancela la petición cuando el componente se desmonta
    return () => {
      if (source) {
        source.cancel("Componente desmontado");
      }
    };
  }, [selectedColumns, columnFilters, globalFilter, pagination, sorting, t]);

  const changeColumn = (selection) => {
    if (selection?.pathology == 1) {
      setSelectedColumns(columnsICC);
    } else if (selection?.pathology == 2 && selection?.modality == 1) {
      setSelectedColumns(columnsHTAProtocol);
    } else if (selection?.pathology == 2 && selection?.modality == 2) {
      setSelectedColumns(columnsHTAMaintainance);
    }
  };

  const handleExportData = () => {
    updateTable(selectedPathology, selectedMedicalGroup, false, true);
  };

  React.useEffect(() => {
    if (dataToExport.length > 0) {
      let json = null;

      if (selectedPathology.pathology == 2) {
        if (selectedPathology.modality == 1) {
          json = dataToExport.map((row) => {
            return [
              row.fullname,
              row.antiquity,
              row.weight 
                ? row.weight
                : "--",
              row.bp_values.measurement_dates.value,
              row.count_measurements,
              row.bp_values.bp_morning_average.value,
              row.bp_values.bp_afternoon_average.value,
              row.bp_values.bp_weekly_average.value,
              row.hr_values.hr_morning_afternoon,
              row.hr_values.hr_general_average
            ];
          });
        } else {
          json = dataToExport.map((row) => {
            return [
              row.fullname,
              row.antiquity,
              row.weight 
                ? row.weight
                : "--",
              row.count_measurements,
              row.bp_values.bp_average,
              row.hr_values.hr_average,
              row.bp_values.bp_max,
              row.hr_values.hr_max,
              row.bp_values.sys_out,
              row.bp_values.dias_out,
            ];
          });
        }
      } else {
        json = dataToExport.map((row) => {
          return [
            row.fullname,
            row.antiquity,
            row.FEVI?.value != null
              ? row.FEVI?.value + "%"
              : "--",
            row.drugs,
            row.weekly_adherence,
            row.last_data_loaded 
              ? row.last_data_loaded
              : "--",
            row.hr_today 
              ? t('yes')
              : t('no'),
            row.bp_weekly_average.value,
            row.face_today 
              ? row.face_today
              : "--",
          ];
        });
      }
      csvExporter.generateCsv(json);
    }
  }, [dataToExport])

  const styles = {
    filter: {
      fontSize: "18px",
      fontFamily: "Poppins",
      fontWeight: "600",
      lineHeight: "24px",
      letterSpacing: "0.18px",
    },
    buttons: {
      display: "flex",
      width: "207px",
      padding: "12px 26px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      marginTop: "20px",
      borderRadius: "15px",
    },
    loading: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
    },
    correctChip: {
      backgroundColor: "rgba(158, 219, 160, 0.40)",
      color: "#38753A",
    },
    wrongChip: {
      backgroundColor: "rgba(227, 143, 143, 0.20)",
      color: "#D04444",
    },
    yellowChip: {
      backgroundColor: "rgba(255, 140, 0, 0.2)",
      color: "#FF8C00",
    },
    th: {
      whiteSpace: "pre-line", // Corregir el valor de whiteSpace
    },
  };
  return (
    <div>
      <SelectPathologyWithModalityAndMedicalGroup
        endpoint="health_panel"
        preferencePathology="health_panel_default_pathology"
        preferenceMedicalGroup="health_panel_default_medical_group"
        setSelectedPathology={setSelectedPathology}
        setSelectedMedicalGroup={setSelectedMedicalGroup}
        selectedPathology={selectedPathology}
        selectedMedicalGroup={selectedMedicalGroup}
        updateTable={updateTable}
      />
      {!isLoading ? (
        <>
          <Box marginTop={"10px"}>
            <MaterialTable
              columns={selectedColumns}
              data={data?.data ?? []}
              title="Dashboard"
              muiToolbarAlertBannerProps={
                isError
                  ? {
                    color: "error",
                    children: t("error_fetching_data_from_server"),
                  }
                  : undefined
              }
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              rowCount={rowCount}
              state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
                sorting,
              }}
            />
          </Box>
          <div
            align="right"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "20px",
            }}
          >
            <Button
              sx={[styles.buttons, { color: "#3657D9", background: "white" }]}
              color="primary"
              onClick={handleExportData}
              endIcon={!isLoadingExport? <img src="export.png" /> : <></>}
              variant="contained"
              disabled={isLoadingExport}
            >
              {!isLoadingExport? t("export") : t("loading")}
            </Button>
            <Button
              sx={[styles.buttons, { color: "white", background: "#3657D9" }]}
              onClick={() =>
                selectedPathology == 1
                  ? navigate("./glossaryICC")
                  : selectedPathology?.modality === 1
                    ? navigate("./glossaryHTA")
                    : navigate("./glossaryHTA-maintenance")
              }
              endIcon={<img src="glossary.png" />}
            >
              {t("glosary")}
            </Button>
          </div>
        </>
      ) : data?.message ? (
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ backgroundColor: "white" }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center" }}
            >
              {t("no_exist_patient")}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : (
        <>
          <InputLabel sx={[styles.loading, { textAlign: "center" }]}>
            {t("loading")}
          </InputLabel>
        </>
      )}
    </div>
  );
};

export default HealthDashboardIndexPage;
