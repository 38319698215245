import React from "react";
import ReactECharts from "echarts-for-react";
import { Box, Typography } from "@mui/material";

const PieChart = (props) => {
  const { data, title, subtitle, colors, labels } = props;
  const echartsRef = React.useRef(null);

  const option1 = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      center: "center",
      padding: 0,
      bottom: 0,
    },
    color: colors,
    series: [
      {
        name: props.title,
        label: {
          show: false,
        },
        type: "pie",
        radius: "50%",
        data: data.map((value, index) => ({
          name: labels[index],
          value: value,
        })),
      },
    ],
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (echartsRef) {
        props.chartImage(
          echartsRef?.current?.getEchartsInstance().getDataURL(),
          props.title
        );
      }
    }, 1000);
  }, [echartsRef])

  const styles = {
    card: {
      padding: "40px 40px 20px 40px",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "26.862px",
      background: "var(--Grayscale-Off-white, #FCFCFC)",
      boxShadow: "0px 1.612px 35.816px 0px rgba(0, 0, 0, 0.07)",
      height: "540px",
      marginBottom: "5%",
      position: "relative"
    },
    titleContainer: {
      fontSize: 18,
      fontWeight: "bold",
      maxWidth: "100%",
      wordWrap: "break-word",
      textAlign: "start",
    },
    subtitleContainer: {
      fontSize: 14,
      wordWrap: "break-word",
      textAlign: "center",
      textAlign: "start",
    },
    footer: {
      color: "#737589",
      fontSize: "12px",
      fontWeight: 400,
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
    },
    div: {
      height: "1px",
      width: "100%",
      background: "#A5A7B6",
      marginTop: "5%",
    },
  };
  return (
    <Box sx={styles.card}>
      <Typography style={styles.titleContainer}>{title}</Typography>
      <Typography style={styles.subtitleContainer}>{subtitle}</Typography>
      {props.space ? <Box>{props?.space}</Box> : <></>}
      <ReactECharts ref={echartsRef} option={option1} />

      {props.footer ? (
        <Box position={'absolute'} bottom={"20px"}>
          <Box sx={styles.div} />
          <Box sx={styles.footer}>
            <img style={{ marginRight: "5px" }} src="/clock.png" />
            {props?.footer}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
export default PieChart;
