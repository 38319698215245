import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectModality = (props) => {
    const { t } = useTranslation();
    const styles = {
        centered: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        container: {
            padding: "3px 10px",
            height: "41px",
            borderRadius: "20px",
            display: "flex",
            gap: "10px",
            background: "#E2E3EC",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "311px"
        },
        selected: {
            background: "#737589",
            color: "#FCFCFC"
        },
        unselected: {
            color: "#A5A7B6",
            cursor: "pointer",
        },
        button: {
            padding: "3px 20px",
            borderRadius: "15px",
            height: "30px",
            textAlign: "center",
            
        }
    }
    return (
        <Box sx={styles.centered}>
            <Box sx={styles.container}>
                <Box sx={[props.modality == 1 ? styles.selected : styles.unselected, styles.button]}
                    onClick={() => {props.selectedModality(1)}}>
                    {t("protocol")}
                </Box>
                <Box sx={[props.modality == 2 ? styles.selected : styles.unselected, styles.button]}
                    onClick={() => {props.selectedModality(2)}}>
                    {t("maintenance")}
                </Box>
            </Box>
        </Box>
    )
}
export default SelectModality;