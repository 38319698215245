import { Grid, IconButton, Button, Box } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import MaterialTable from "../../components/MaterialTable";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import WithMessages from "../../components/WithMessages";
import { ExportToCsv } from "export-to-csv";
import SelectPathologyAndMedicalGroup from "../../components/SelectPathologyAndMedicalGroup";
import { useTranslation } from "react-i18next";

const SurveysIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  props = { ...props, user };
  const [surveys, setSurveys] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [selectedPathology, setSelectedPathology] = React.useState(1);
  const [selectedMedicalGroup, setSelectedMedicalGroup] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        header: t("name"),
        key: "fullname",
        accessorKey: "name",
        size: 150,
      },
      {
        id: "antiquity",
        accessorKey: "antiquity",
        header: t("antiquity"),
        key: "antiquity",
        size: 100,
      },
      {
        id: "Nivel de autocuidado",
        accessorKey: "Nivel de autocuidado",
        header: t("self_care_level"),
        key: "Nivel de autocuidado",
        size: 100,
        Cell: (cell) => (
          <Grid spacing={1} container>
            {cell.row.original["Nivel de autocuidado"] ? (
              cell.row.original["Nivel de autocuidado"] == "Active" ? (
                <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                  <img src="/warning.png" />
                </Grid>
              ) : (
                <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                  {cell.row.original["Nivel de autocuidado"]}
                </Grid>
              )
            ) : (
              <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                -
              </Grid>
            )}
          </Grid>
        ),
      },
      {
        id: "Calidad de vida del paciente",
        key: "Calidad de vida del paciente",
        accessorKey: "Calidad de vida del paciente",
        header: t("patient_quality_life"),
        size: 100,
        Cell: (cell) => (
          <Grid spacing={1} container>
            {cell.row.original["Calidad de vida del paciente"] ? (
              cell.row.original["Calidad de vida del paciente"] == "Active" ? (
                <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                  <img src="/warning.png" />
                </Grid>
              ) : (
                <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                  {cell.row.original["Calidad de vida del paciente"]}
                </Grid>
              )
            ) : (
              <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                -
              </Grid>
            )}
          </Grid>
        ),
      },
      {
        id: "Adherencia a la medicación",
        key: "Adherencia a la medicación",
        accessorKey: "Adherencia a la medicación",
        header: t("medication_Adherence"),
        size: 100,
        Cell: (cell) => (
          <Grid spacing={1} container>
            {cell.row.original["Adherencia a la medicación"] ? (
              cell.row.original["Adherencia a la medicación"] == "Active" ? (
                <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                  <img src="/warning.png" />
                </Grid>
              ) : (
                <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                  {cell.row.original["Adherencia a la medicación"]}
                </Grid>
              )
            ) : (
              <Grid item xs={9} sx={{ mt: 2, textAlign: "center" }}>
                -
              </Grid>
            )}
          </Grid>
        ),
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          [
            "superadmin",
            "admin-customer",
            "admin-institution",
            "doctor",
          ].some(r => props.role.includes(r)) ? (
            <IconButton
              onClick={() =>
                navigate(`/surveys/patient/${cell.row.original["id"]}`, {
                  state: {
                    patient: cell.row.original.name,
                  },
                })
              }
              aria-label="view"
            >
              <img src="/view.png" alt="view" />
            </IconButton>
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t]
  );

  const csvOptions = {
    fieldSeparator: ";",
    filename: "Tablero Encuestas",
    decimalSeparator: ",",
    useBom: true,
    useKeysAsHeaders: false,
    showLabels: true,
    headers: columns.map((c) => c.header).slice(0, -1),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  React.useEffect(() => {
    if (selectedPathology !== "") {
      updateTable(selectedPathology, selectedMedicalGroup);
    }
  }, [
    selectedMedicalGroup,
    columnFilters,
    globalFilter,
    pagination,
    sorting,
    user,
  ]);

  const updateTable = (pathologyId, medicalGroupId) => {
    if (pathologyId != null && medicalGroupId != null) {
      setSurveys([]);
      const fetchData = async () => {
        const url = new URL("/institutions/v1/surveys", dotenv.API_URL);
        url.searchParams.set("page", `${pagination.pageIndex + 1}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
        url.searchParams.set("pathologyId", 1);
        url.searchParams.set("medicalGroupId", medicalGroupId);
        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            setSurveys(response.data);
          });
      };
      fetchData();
    }
  };

  const handleExportData = () => {
    let dataToExport = surveys.map((row) => {
      return [
        row.name,
        row.antiquity,
        row["Nivel de autocuidado"],
        row["Calidad de vida del paciente"],
        row["Adherencia a la medicación"],
      ];
    });
    csvExporter.generateCsv(dataToExport);
  };

  const styles = {
    buttons: {
      display: "flex",
      width: "207px",
      padding: "12px 26px",
      justifyContent: "center",
      gap: "10px",
      marginTop: "20px",
      borderRadius: "15px",
    },
  };

  return (
    <div>
      <Box sx={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
        <SelectPathologyAndMedicalGroup 
          endpoint = "survey"
          preferencePathology = "survey_panel_default_pathology"
          preferenceMedicalGroup = "survey_panel_default_medical_group"
          setSelectedPathology = {setSelectedPathology}
          setSelectedMedicalGroup = {setSelectedMedicalGroup}
          selectedPathology = {selectedPathology}
          selectedMedicalGroup = {selectedMedicalGroup}
          updateTable = {updateTable}
          onlyPathology = "ICC"
        />
        <Button
          sx={[
            styles.buttons,
            {
              color: "white",
              background: "#3657D9",
              width: 303,
              height: "54px",
              alignSelf: "end",
            },
          ]}
          onClick={() => navigate("./customize-surveys")}
          endIcon={<img src="params-white.png" />}
        >
          {t("parameterize")}
        </Button>
      </Box>
      <MaterialTable
        columns={columns}
        data={surveys}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: t("error_fetching_data"),
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
      <div
        align="right"
        style={{ display: "flex", flexDirection: "row-reverse", gap: "20px" }}
      >
        <Button
          sx={[styles.buttons, { color: "white", background: "#3657D9" }]}
          onClick={() => navigate("./glossary")}
          endIcon={<img src="glossary.png" />}
        >
          {t("glosary")}
        </Button>
        <Button
          sx={[
            styles.buttons,
            { color: "#3657D9", background: "white", width: "155px" },
          ]}
          color="primary"
          onClick={handleExportData}
          endIcon={<img src="export.png" />}
          variant="contained"
        >
          {t("export")}
        </Button>
      </div>
    </div>
  );
};
export default WithMessages(SurveysIndexPage);
