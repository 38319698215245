import { IconButton, Button, Chip } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import MaterialTable from "../../../components/MaterialTable";
import { useNavigate } from "react-router-dom";
import React, { useTransition } from "react";
import axios from "axios";
import dotenv from "react-dotenv";
import WithMessages from "../../../components/WithMessages";
import { ExportToCsv } from "export-to-csv";
import { Translation, useTranslation } from "react-i18next";

const CustomizeSurveysIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [templates, setTemplates] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [disabledActions, setDisabledActions] = React.useState(false);
  const [rowSelection, setRowSelection] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const tableInstanceRef = React.useState({});
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = React.useMemo(
    () => [
      {
        id: "title",
        header: t("survey_name"),
        key: "title",
        accessorKey: "title",
        size: 240,
      },
      {
        id: "pathologies",
        accessorKey: "pathologies",
        header: t("patologies"),
        key: "pathologies",
        size: 100,
        Cell: (cell) => (
          <>
            {cell.row.original.pathologies.map((pathology) => (
              <Chip
                sx={[
                  pathology.id == 1 ? styles.chipICC : styles.chipHTA,
                  styles.chip,
                ]}
                key={pathology.id}
                label={pathology.name}
              />
            ))}
          </>
        ),
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 150,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          [
            "superadmin",
            "admin-customer",
            "admin-institution",
            "doctor",
          ].some(r => props.role.includes(r)) ? (
            <>
              <IconButton
                className="survey-action"
                onClick={() =>
                  navigate(`/surveys/${cell.row.original["id"]}`, {
                    state: {
                      template: cell.row.original.title,
                    },
                  })
                }
                aria-label="view"
              >
                <img src="/view.png" alt="view" />
              </IconButton>
              <IconButton
                className="survey-action"
                onClick={() =>
                  navigate(`./${cell.row.original["id"]}`, {
                    state: {
                      template: cell.row.original.title,
                    },
                  })
                }
                aria-label="view"
              >
                <img src="/edit.png" alt="view" />
              </IconButton>
              <IconButton
                className="survey-action"
                onClick={() =>
                  navigate(`/surveys/send/${cell.row.original["id"]}`, {
                    state: {
                      title: cell.row.original.title,
                    },
                  })
                }
                aria-label="view"
              >
                <img src="/send.png" alt="view" />
              </IconButton>
            </>
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t]
  );

  const csvOptions = {
    fieldSeparator: ";",
    filename: t("survey_dashboard_template"),
    decimalSeparator: ",",
    useBom: true,
    useKeysAsHeaders: false,
    showLabels: true,
    headers: columns.map((c) => c.header).slice(0, -1),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  React.useEffect(() => {
    const fetchData = async () => {
      const url = new URL("/institutions/v1/templates", dotenv.API_URL);
      url.searchParams.set("page", `${pagination.pageIndex + 1}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setTemplates(response.data);
        });
    };
    fetchData();
  }, [columnFilters, globalFilter, pagination, sorting, user]);

  React.useEffect(() => {
    Object.keys(rowSelection).length > 1
      ? setDisabledActions(true)
      : setDisabledActions(false);
  }, [rowSelection]);

  React.useEffect(() => {
    var elementos = document.querySelectorAll(".survey-action");
    elementos.forEach(function (elemento) {
      if (disabledActions) {
        elemento.style.visibility = "hidden";
      } else {
        elemento.style.visibility = "visible";
      }
    });
  }, [disabledActions]);

  const handleExportData = () => {
    let dataToExport = templates.map((row) => {
      return [row.name, row.pathologies];
    });
    csvExporter.generateCsv(dataToExport);
  };

  const customizeSelectedTemplates = (rows) => {
    navigate("./multiple", {
      state: {
        selectedTemplates: rows.map((row) => {
          return row.original.id;
        }),
      },
    });
  };

  const styles = {
    buttons: {
      display: "flex",
      padding: "12px 26px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      marginTop: "20px",
      borderRadius: "15px",
    },
    chip: {
      borderRadius: "16px",
      height: "22px",
    },
    chipICC: {
      backgroundColor: "#E7E9FF",
      color: "#3657D9",
    },
    chipHTA: {
      backgroundColor: "#C3EBDF",
      color: "#208266",
    },
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={templates}
        title="Encuestas"
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: t("error_fetching_data"),
              }
            : undefined
        }
        enableRowSelection
        tableInstanceRef={tableInstanceRef}
        onRowSelectionChange={setRowSelection}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          rowSelection,
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
      <div
        align="right"
        style={{ display: "flex", flexDirection: "row-reverse", gap: "20px" }}
      >
        <Button
          sx={[
            styles.buttons,
            { color: "#3657D9", background: "white", width: "155px" },
          ]}
          color="primary"
          onClick={handleExportData}
          endIcon={<img src="/export.png" />}
          variant="contained"
        >
          {t("export")}
        </Button>
        <Button
          disabled={Object.keys(rowSelection).length === 0}
          sx={[
            styles.buttons,
            { width: 275, color: "white", background: "#3657D9" },
          ]}
          variant="contained"
          endIcon={<img src={"/customize-surveys.png"} />}
          onClick={() =>
            customizeSelectedTemplates(
              tableInstanceRef.current.getSelectedRowModel().rows
            )
          }
        >
          {t("config_surveys")}
        </Button>
      </div>
    </div>
  );
};
export default WithMessages(CustomizeSurveysIndexPage);
