import { InputLabel, Box } from "@mui/material";
import React from "react";
import { useAuth } from '../../../hooks/useAuth';
import axios from 'axios';
import dotenv from 'react-dotenv';
import QuestionSurveyCard from "../../../components/QuestionSurveyCard";
import { useLocation, useParams } from 'react-router-dom';

const SurveyAnswersPage = (props) => {
    const { user } = useAuth();
    const { id, surveyId } = useParams();
    const [ survey, setSurvey ] = React.useState();
    const location = useLocation();

    React.useEffect(()=>{
        const fetchData = async () => {
            const url = new URL(`/institutions/v1/surveys/${surveyId}/results`, dotenv.API_URL);
            axios.get(url.href, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + user.token
                }
            })
            .then((response) => {
                if (response.data){
                    setSurvey(response.data[0]);
                    props.setTitle(["Pacientes",location.state.patient,"Encuestas",response.data[0].template.title],"");
                }
            })
            .catch()
            .finally();
        };
        fetchData();
    },[])

    const styles = {
        survey: {
            margin: "29px",
            width: "100%",
            title: {
                color: "#285D84",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "33px",
                textAlign: "center",
            },
            description: {
                textAlign: "center",
                color: "#285D84",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.3px",
            },
            instructions: {
                color: "#285D84",
                marginTop: "22px",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "150%",
                letterSpacing: "-0.011em",
            }
        },
        questions: {
            display: "flex",
            flexDirection: "column",
            margin: "0 auto 20px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: "50px",
            paddingBottom: "20px",
            question: {
                justifyContent: "center",
                width: "87%",
                marginTop: "10px",
            },
            send_button: {
                marginTop: "12px",
                marginBottom: "70px",
                height: "46px",
                width: "87%",
                background: "linear-gradient(265.22deg, #3677A7 44.62%, #285D84 101.88%)",
                boxShadow: "2px 2px 6px rgba(54, 119, 167, 0.25)",
                borderRadius: "30px",
                border: "0",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "12px",
                lineHeight: "20px",
                color: "white",
            }
        }
    }

    return (
        <div>
            <Box sx={styles.survey}>
                <InputLabel sx={styles.survey.title}>
                    {survey?.template.title}
                </InputLabel>
                <Box sx={styles.survey.description}>
                    {survey?.template.description}
                </Box>
                <Box sx={styles.survey.instructions}>
                    {survey?.template.instructions}
                </Box>
            </Box>
            <Box sx={styles.questions}>
                <Box sx={styles.questions.question} >
                    {
                        survey?.answers.map(answer =>(
                            <QuestionSurveyCard
                                key={answer.id}
                                question={answer.question}
                                answer={answer.answer_data.value}
                            ></QuestionSurveyCard>
                        ))
                    }
                </Box>
            </Box>
        </div>
    )
}
export default SurveyAnswersPage;