export default function ChagenResponse(res) {
  return res.map((medicine) => ({
    id: medicine.id,
    laboratory: medicine.laboratory ?? "",
    monodrug: medicine.monodrug ?? "",
    name: medicine.name ?? "",
    potency: medicine.potency ?? "",
    potency_unit: medicine.potency_unit ?? "",
    presentation: medicine.presentation ?? "",
    status: medicine.status ?? "",
    unit_type: medicine.unit_type ?? "",
    vademecum: medicine.vademecum ?? "",
  }));
}
