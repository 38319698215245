import React, { useState } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Container,
  Button,
  Grid,
} from "@mui/material";
import dotenv from "react-dotenv";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

const SendSurveysButtons = (props) => {
  const { user } = useAuth();
  const institutions = user.user.institutions;
  const [medicalGroups, setMedicalGroups] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const [selectedInstitution, setSelectedInstitution] = React.useState(
    institutions[0].id
  );
  const [selectedMedicalGroup, setSelectedMedicalGroup] = useState();
  const [selectedButtons, setSelectedButtons] = useState([]);

  const handleChange = (event) => {
    if (event.target.name == "institution") {
      setSelectedInstitution(event.target.value);
    } else {
      setSelectedMedicalGroup(event.target.value);
    }
  };

  const toggleButton = (index) => {
    const newSelectedButtons = [...selectedButtons];
    newSelectedButtons[index] = !newSelectedButtons[index];
    setSelectedButtons(newSelectedButtons);
  };
  const toggleAllButtons = () => {
    const allSelected = selectedButtons.every((isSelected) => isSelected);
    const newSelectedButtons = Array(selectedButtons.length).fill(!allSelected);
    setSelectedButtons(newSelectedButtons);
  };

  React.useEffect(() => {
    if (props.mode == "institution") {
      setSelectedButtons(Array(institutions.length).fill(false));
    }
  }, []);

  React.useEffect(() => {
    if (props.mode != "institution") {
      setLoading(true);
      const fetchData = async () => {
        const url = new URL(`/institutions/v1/medical-groups`, dotenv.API_URL);
        url.searchParams.set("institution", `${selectedInstitution}`);
        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            if (response.data.data) {
              setMedicalGroups(response.data.data);
              if (props.mode == "medicalGroup") {
                setSelectedButtons(
                  Array(response.data.data.length).fill(false)
                );
              } else {
                setSelectedMedicalGroup(response.data.data[0].id);
              }
            }
          })
          .catch()
          .finally(() => setLoading(false));
      };
      fetchData();
    } else {
      setSelectedButtons(Array(institutions.length).fill(false));
    }
  }, [selectedInstitution, props.mode]);

  React.useEffect(() => {
    if (props.mode == "patient") {
      setLoading(true);
      const fetchData = async () => {
        const url = new URL(`/institutions/v1/memberships`, dotenv.API_URL);
        url.searchParams.set("paginate", 0);
        url.searchParams.set("medicalGroup", `${selectedMedicalGroup}`);
        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            if (response.data) {
              setPatients(response.data);
              setSelectedButtons(Array(response.data.length).fill(false));
            }
          })
          .catch()
          .finally(() => setLoading(false));
      };
      fetchData();
    }
  }, [selectedMedicalGroup, props.mode]);

  const styles = {
    select: {
      width: "226px",
      height: "55px",
      padding: "16px, 9px, 15px, 16px",
      borderRadius: "12px",
      border: "1px",
      marginRight: "10px",
    },
    caja: {
      width: "100%",
      height: "100%",
      padding: "16px",
      borderRadius: "14px",
      border: "0px, 0px, 1px, 0px",
      gap: "16px",
      background: "#EAECF0",
    },

    allSelect: {
      marginTop: "20px",
      backgroundColor: "#3988E3",
      color: "white",
    },
    allSelectInactive: {
      marginTop: "20px",
      backgroundColor: "#A9CCE3",
      color: "white",
    },
    butbox: {
      display: "flex",
    },
    buttons: {
      marginTop: "16px",
      gap: "10px",
    },
    sendBut: {
      marginTop: "15px",
      marginRight: "2%",
      width: "201px",
      padding: "12px 26px",
      borderRadius: "15px",
      background: "var(--success-default, #5EC360)",
      color: "white",
    },
    conteinerBox: {
      marginTop: "2%",
    },
  };

  const showButtons = () => {
    return (
      (props.mode == "institution" &&
        institutions.length == selectedButtons.length) ||
      (props.mode == "medicalGroup" &&
        medicalGroups.length == selectedButtons.length) ||
      (props.mode == "patient" && patients.length == selectedButtons.length)
    );
  };

  return (
    <div>
      <Container fixed sx={styles.conteinerBox}>
        <Grid container sx={styles.allCont}>
          <Container fixed>
            <Grid container>
              <Grid sx={styles.caja}>
                {props.mode == "medicalGroup" || props.mode == "patient" ? (
                  <FormControl fullWidth sx={styles.select}>
                    <Select
                      name="institution"
                      value={selectedInstitution}
                      onChange={handleChange}
                    >
                      {institutions.map((x) => (
                        <MenuItem key={x.id} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                {props.mode == "patient" && selectedMedicalGroup ? (
                  <FormControl fullWidth sx={styles.select}>
                    <Select
                      name="medicalGroup"
                      value={selectedMedicalGroup}
                      onChange={handleChange}
                    >
                      {medicalGroups.map((x) => (
                        <MenuItem key={x.id} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                {showButtons() ? (
                  <>
                    <Grid>
                      <Button
                        sx={
                          selectedButtons.every((isSelected) => isSelected)
                            ? styles.allSelect
                            : styles.allSelectInactive
                        }
                        onClick={toggleAllButtons}
                      >
                        {t("select_all")}
                      </Button>
                    </Grid>
                    <Grid container spacing={2}>
                      {selectedButtons.map((isSelected, index) => (
                        <Grid
                          sx={styles.buttons}
                          item
                          xs={6}
                          sm={2}
                          md={3}
                          key={index}
                        >
                          <Button
                            onClick={() => toggleButton(index)}
                            style={{
                              backgroundColor: isSelected
                                ? "#3988E3"
                                : "#A9CCE3",
                              color: "white",
                            }}
                          >
                            {props.mode == "institution" ? (
                              institutions[index].name
                            ) : props.mode == "medicalGroup" &&
                              medicalGroups.length > 0 ? (
                              medicalGroups[index].name
                            ) : props.mode == "patient" &&
                              patients.length > 0 ? (
                              patients[index].name +
                              " " +
                              patients[index].lastname
                            ) : (
                              <></>
                            )}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <div align="right">
          <Button
            sx={styles.sendBut}
            endIcon={<img src="/IconSend.png" />}
            onClick={() =>
              props.sendSurveys(
                selectedButtons,
                props.mode == "institution"
                  ? institutions
                  : props.mode == "medicalGroup"
                  ? medicalGroups
                  : patients,
                props.mode == "institution"
                  ? "assign-by-institution"
                  : props.mode == "medicalGroup"
                  ? "assign-by-medical-group"
                  : "assign",
                props.mode == "patient" ? selectedMedicalGroup : null
              )
            }
          >
            {t("send")}
          </Button>
        </div>
      </Container>
    </div>
  );
};
export default SendSurveysButtons;
