import { Box } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

const styles = {
  boxContainer: {
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.80)",
    padding: "10px",
  },
  boxTitles: {
    color: "#575565",
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Poppins",
  },
  itemsTitle: {
    color: "#3657D9",
    fontSize: "11px",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
};

const PatientData = (props) => {
  const { t } = useTranslation();
  return (
    <Box sx={styles.boxContainer}>
      <Box sx={styles.boxTitles}>{t("data_patients")}</Box>
      <Box display={"flex"} mt={"10px"} justifyContent={"space-between"}>
        <Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("name")}:{" "}
            <span style={{ color: "#869AE8" }}>
              {" "}
              {props.profile_data?.name} {props.profile_data?.lastname}
            </span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("sex")}:{" "}
            <span style={{ color: "#869AE8" }}>
              {" "}
              {props.profile_data?.gender}
            </span>
          </Box>
        </Box>
        <Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("document")}:{" "}
            <span style={{ color: "#869AE8" }}>
              {" "}
              {props.profile_data?.id_number}
            </span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("age")}:{" "}
            <span style={{ color: "#869AE8" }}> {props.profile_data?.age}</span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("date_birth")}:{" "}
            <span style={{ color: "#869AE8" }}>
              {" "}
              {moment(props.profile_data?.birthdate).format("DD/MM/yy")}
            </span>
          </Box>
        </Box>
        <Box mr="20px">
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("weight")}:{" "}
            <span style={{ color: "#869AE8" }}>
              {" "}
              {props.profile_data?.weight?.value}
            </span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            {t("height")}:{" "}
            <span style={{ color: "#869AE8" }}>
              {" "}
              {props.profile_data?.height?.value}
            </span>
          </Box>
          <Box sx={[styles.itemsTitle, { color: "#3657D9" }]}>
            IMC:{" "}
            <span style={{ color: "#869AE8" }}> {props.profile_data?.imc}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default PatientData;
