import React from "react";
import { Box, InputLabel, Select, MenuItem } from "@mui/material";
import CustomizeParameters from "./CustomizeParameters";
import withMessages from "./WithMessages";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { useTranslation } from "react-i18next";

const CustomizeParametersWithPathology = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [data, setData] = React.useState(null);
  const defaultWidth = window.innerWidth - 380 + "px";
  const pathologies = [
    {
      id: 1,
      name: "ICC",
    },
    {
      id: 2,
      name: "HTA",
    },
  ];
  const [selectedPathology, setSelectedPathology] = React.useState(1);

  React.useEffect(() => {
    getPathologyParams();
  }, [selectedPathology]);

  const getPathologyParams = () => {
    let pathologyUrl = "";
    if (props.withPathology) {
      pathologyUrl = `/${selectedPathology}`;
    }
    const fetchData = async () => {
      const url = new URL(
        `/institutions/v1/${props.endpoint}${pathologyUrl}`,
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setData(response.data);
          }
        })
        .catch()
        .finally();
    };
    fetchData();
  };

  const updateParams = (paramUpdate) => {
    let pathologyUrl = "";
    if (props.withPathology) {
      pathologyUrl = `/${selectedPathology}`;
    }
    const url = new URL(
      `/institutions/v1/${props.endpoint}${pathologyUrl}`,
      dotenv.API_URL
    );
    axios
      .post(url.href, paramUpdate, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        props.showMessage(t("modify_params_succes"));
      })
      .catch((error) =>
        props.showMessage(
          error.response?.data?.errors[
            Object.keys(error.response?.data?.errors)[[0]]
          ],
          null,
          "error"
        )
      )
      .finally(() => {});
  };

  const handlePathologyChange = (event) => {
    setSelectedPathology(event.target.value);
  };

  const styles = {
    patientName: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
    },
    filter: {
      fontSize: "18px",
      fontFamily: "Poppins",
      fontWeight: "600",
      lineHeight: "24px",
      letterSpacing: "0.18px",
    },
    select: {
      backgroundColor: "#FCFCFC",
      borderRadius: "8px",
      border: "1px solid #869AE8",
      color: "#869AE8",
      marginLeft: "10px",
    },
  };
  return (
    <>
      {pathologies && props.withPathology ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InputLabel sx={styles.filter}>{t("patology")}: </InputLabel>
          <Select
            displayEmpty
            sx={styles.select}
            id="patology-input"
            name="selectedPathology"
            value={selectedPathology}
            onChange={handlePathologyChange}
          >
            {pathologies.map((pathology) => (
              <MenuItem value={pathology.id} key={pathology.id}>
                {pathology.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      ) : (
        <></>
      )}
      {data ? (
        <Box>
          <Box>
            <InputLabel sx={styles.patientName}>{data.patient}</InputLabel>
            <CustomizeParameters
              data={{ params: data }}
              firstTitle={"Valor actual"}
              secondTitle={"Nuevo valor"}
              updateParams={updateParams}
            ></CustomizeParameters>
          </Box>
        </Box>
      ) : (
        <InputLabel sx={[styles.patientName, { textAlign: "center" }]}>
          {t("loading")}
        </InputLabel>
      )}
      <br />
    </>
  );
};
export default withMessages(CustomizeParametersWithPathology);
