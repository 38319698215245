import * as React from "react";
import withMessages from "../../../components/WithMessages";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { SelectSx, TextField } from "../../../theme/vs-theme";
import {
  Select,
  Box,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import dotenv from "react-dotenv";
import moment from "moment";
import { useTranslation } from "react-i18next";
const ModifyMedicalInformationICC = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const [errors, setErrors] = React.useState({});
  const [disneas, setDisneas] = React.useState([]);
  const { id } = useParams();
  const { t } = useTranslation();
  const [form, setForm] = React.useState({
    disnea: null,
    disnea_date: null,
    about_patient: null,
    about_patient_date: null,
    valve_disease: null,
    valve_disease_date: null,
    baseline_myocardia: null,
    baseline_myocardia_date: null,
    fevi: null,
    fevi_date: null,
    hematocrit: null,
    hematocrit_date: null,
    urea: null,
    urea_date: null,
    creatinine: null,
    creatinine_date: null,
    sodium: null,
    sodium_date: null,
    potassium: null,
    potassium_date: null,
    target_weight_date: null,
    target_weight: null,
  });

  const styles = {
    column: {
      margin: "0.5%",
      width: "49%",
      gap: "40px",
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      gap: "10px",
    },
    inputFields: {
      borderRadius: "12px",
      border: "1px solid #869AE8",
      padding: "0 20px",
      fontFamily: "Poppins",
      color: "#869AE8",
      fontSize: "14px",
      height: "46px",
      minWidth: "150px",
      width: "100%",
      marginTop: "5px",
    },
    backgroundBox: {
      width: "100%",
      padding: "40px 40px",
      backgroundColor: "#FFFFFF",
      borderRadius: "18px",
      margin: "10px 0px",
    },
    buttons: {
      marginTop: "40px",
      borderRadius: "15px",
      color: "white",
    },
    fields: {
      borderRadius: "12px",
      backgroundColor: "#FCFCFC",
      border: "1px solid #869AE8",
      padding: "10px 12px",
      color: "#869AE8",
    },
  };

  React.useEffect(() => {
    if (location.state?.patient) {
      props.setTitle(["Pacientes", location.state.patient], "");
    }
    getHealthData();
  }, []);

  const getHealthData = () => {
    const fetchData = async () => {
      const url = new URL(
        "/institutions/v1/patients/" + id + "/health-icc",
        dotenv.API_URL
      );
      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          if (response.data) {
            setDisneas(response.data.disnea);
            const parameters = response.data.parameters.reduce(
              (obj, item) => ({ ...obj, ...item }),
              {}
            );
            setForm({
              disnea: parameters.disnea,
              disnea_date: moment(
                parameters.disnea_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.disnea_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              about_patient: parameters.about_patient,
              about_patient_date: moment(
                parameters.about_patient_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.about_patient_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              valve_disease: parameters.valve_disease,
              valve_disease_date: moment(
                parameters.valve_disease_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.valve_disease_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              baseline_myocardia: parameters.baseline_myocardia,
              baseline_myocardia_date: moment(
                parameters.baseline_myocardia_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(
                    parameters.baseline_myocardia_date,
                    "DD-MM-YYYY"
                  ).format("YYYY-MM-DD")
                : null,
              fevi: parameters.fevi,
              fevi_date: moment(parameters.fevi_date, "DD-MM-YYYY").isValid()
                ? moment(parameters.fevi_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              hematocrit: parameters.hematocrit,
              hematocrit_date: moment(
                parameters.hematocrit_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.hematocrit_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              urea: parameters.urea,
              urea_date: moment(parameters.urea_date, "DD-MM-YYYY").isValid()
                ? moment(parameters.urea_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              creatinine: parameters.creatinine,
              creatinine_date: moment(
                parameters.creatinine_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.creatinine_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              sodium: parameters.sodium,
              sodium_date: moment(
                parameters.sodium_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.sodium_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              potassium: parameters.potassium,
              potassium_date: moment(
                parameters.potassium_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.potassium_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
              target_weight: parameters.target_weight,
              target_weight_date: moment(
                parameters.target_weight_date,
                "DD-MM-YYYY"
              ).isValid()
                ? moment(parameters.target_weight_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
            });
          }
        });
    };
    fetchData();
  };

  const updateMedicalInformation = () => {
    const url = new URL(
      "/institutions/v1/patients/" + id + "/health-data-icc",
      dotenv.API_URL
    );
    axios
      .put(url.href, form, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        props.showMessage(t("was_modified_information_correctly"), () =>
          navigate(-1)
        );
      })
      .catch((error) => {
        setErrors(error.response.data?.errors);
      });
  };

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    let value = event.target.value;
    setForm({ ...form, [event.target.name]: value });
  };

  return (
    <div>
      <Box sx={styles.backgroundBox}>
        <Box display="flex" sx={{ gap: "40px" }}>
          <Box sx={styles.column}>
            <Box sx={styles.row}>
              <Box width={"70%"}>
                <InputLabel htmlFor="disnea-select">
                  {t("functional_class")}
                </InputLabel>
                <Select
                  displayEmpty
                  sx={[SelectSx, { width: "100%" }]}
                  id="disnea-select"
                  name="disnea"
                  value={form.disnea}
                  onChange={handleChange}
                  defaultValue={1}
                >
                  {Object.keys(disneas).map((key) => (
                    <MenuItem value={key} key={key}>
                      {disneas[key]}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                <InputLabel htmlFor="disnea-date">{t("date")}</InputLabel>
                <input
                  type="date"
                  id="disnea-date"
                  value={form.disnea_date}
                  name="disnea_date"
                  onChange={handleChange}
                  style={styles.inputFields}
                />
                <FormHelperText id="disnea-select" sx={{ color: "red" }}>
                  {errors.disnea_date && errors.disnea_date[0]}
                </FormHelperText>
              </Box>
            </Box>
            <Box sx={styles.row}>
              <Box width={"70%"}>
                <InputLabel htmlFor="about-patient">
                  {t("about_patient")}
                </InputLabel>
                <TextField
                  fullWidth
                  id="about-patient"
                  rows={6}
                  multiline
                  value={form.about_patient}
                  name="about_patient"
                  onChange={handleChange}
                />
              </Box>
              <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                <InputLabel htmlFor="about-patient-date">
                  {t("date")}
                </InputLabel>
                <input
                  id="about-patient-date"
                  type="date"
                  value={form.about_patient_date}
                  name="about_patient_date"
                  onChange={handleChange}
                  style={styles.inputFields}
                />
                <FormHelperText id="about-patient-date" sx={{ color: "red" }}>
                  {errors.about_patient_date && errors.about_patient_date[0]}
                </FormHelperText>
              </Box>
            </Box>
            <Box sx={styles.row}>
              <Box width={"70%"}>
                <InputLabel htmlFor="valvulopathies">
                  {t("valvulopathies")}
                </InputLabel>
                <TextField
                  fullWidth
                  id="valvulopathies"
                  rows={6}
                  multiline
                  value={form.valve_disease}
                  name="valve_disease"
                  onChange={handleChange}
                />
              </Box>
              <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                <InputLabel htmlFor="valvulopathies-date">Fecha</InputLabel>
                <input
                  id="valvulopathies-date"
                  type="date"
                  value={form.valve_disease_date}
                  name="valve_disease_date"
                  onChange={handleChange}
                  style={styles.inputFields}
                />
                <FormHelperText id="valvulopathies-date" sx={{ color: "red" }}>
                  {errors.valve_disease_date && errors.valve_disease_date[0]}
                </FormHelperText>
              </Box>
            </Box>
            <Box sx={styles.row}>
              <Box width={"70%"}>
                <InputLabel htmlFor="underlying-cardiomyopathy">
                  {t("underlying_cardiomyopathy")}
                </InputLabel>
                <TextField
                  fullWidth
                  id="underlying-cardiomyopathy"
                  rows={6}
                  multiline
                  value={form.baseline_myocardia}
                  name="baseline_myocardia"
                  onChange={handleChange}
                />
              </Box>
              <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                <InputLabel htmlFor="underlying-cardiomyopathy-date">
                  {t("date")}
                </InputLabel>
                <input
                  id="underlying-cardiomyopathy-date"
                  type="date"
                  value={form.baseline_myocardia_date}
                  name="baseline_myocardia_date"
                  onChange={handleChange}
                  style={styles.inputFields}
                />
                <FormHelperText
                  id="underlying-cardiomyopathy"
                  sx={{ color: "red" }}
                >
                  {errors.baseline_myocardia_date &&
                    errors.baseline_myocardia_date[0]}
                </FormHelperText>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.column}>
            <Box>
              <Box sx={styles.row}>
                <Box width={"70%"}>
                  <InputLabel htmlFor="fevi">FEVI (%)</InputLabel>
                  <TextField
                    fullWidth
                    id="fevi"
                    type="number"
                    value={form.fevi}
                    name="fevi"
                    onChange={handleChange}
                  />
                </Box>
                <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                  <InputLabel htmlFor="fevi-date">{t("date")}</InputLabel>
                  <input
                    id="fevi-date"
                    type="date"
                    value={form.fevi_date}
                    name="fevi_date"
                    onChange={handleChange}
                    style={styles.inputFields}
                  />
                  <FormHelperText id="fevi-date" sx={{ color: "red" }}>
                    {errors.fevi_date && errors.fevi_date[0]}
                  </FormHelperText>
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.fevi && errors.fevi[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"70%"}>
                  <InputLabel htmlFor="hematocrit">
                    {t("hematocrit")} (%)
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="hematocrit"
                    type="number"
                    value={form.hematocrit}
                    name="hematocrit"
                    onChange={handleChange}
                  />
                </Box>
                <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                  <InputLabel htmlFor="hematocrit-date">{t("date")}</InputLabel>
                  <input
                    id="hematocrit-date"
                    type="date"
                    value={form.hematocrit_date}
                    name="hematocrit_date"
                    onChange={handleChange}
                    style={styles.inputFields}
                  />
                  <FormHelperText id="hematocrit-date" sx={{ color: "red" }}>
                    {errors.hematocrit_date && errors.hematocrit_date[0]}
                  </FormHelperText>
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.hematocrit && errors.hematocrit[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"70%"}>
                  <InputLabel htmlFor="urea">{t("urea")}</InputLabel>
                  <TextField
                    fullWidth
                    id="urea"
                    type="number"
                    value={form.urea}
                    name="urea"
                    onChange={handleChange}
                  />
                </Box>
                <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                  <InputLabel htmlFor="urea-date">{t("date")}</InputLabel>
                  <input
                    id="urea-date"
                    type="date"
                    value={form.urea_date}
                    name="urea_date"
                    onChange={handleChange}
                    style={styles.inputFields}
                  />
                  <FormHelperText id="urea-date" sx={{ color: "red" }}>
                    {errors.urea_date && errors.urea_date[0]}
                  </FormHelperText>
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.urea && errors.urea[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"70%"}>
                  <InputLabel htmlFor="creatinine">
                    {t("creatinine")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="creatinine"
                    type="number"
                    value={form.creatinine}
                    name="creatinine"
                    onChange={handleChange}
                  />
                </Box>
                <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                  <InputLabel htmlFor="creatinine-date">{t("date")}</InputLabel>
                  <input
                    id="creatinine-date"
                    type="date"
                    value={form.creatinine_date}
                    name="creatinine_date"
                    onChange={handleChange}
                    style={styles.inputFields}
                  />
                  <FormHelperText id="creatinine-date" sx={{ color: "red" }}>
                    {errors.creatinine_date && errors.creatinine_date[0]}
                  </FormHelperText>
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.creatinine && errors.creatinine[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"70%"}>
                  <InputLabel htmlFor="sodium">{t("sodium")}</InputLabel>
                  <TextField
                    fullWidth
                    id="sodium"
                    type="number"
                    value={form.sodium}
                    name="sodium"
                    onChange={handleChange}
                  />
                </Box>
                <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                  <InputLabel htmlFor="sodium-date">{t("date")}</InputLabel>
                  <input
                    id="sodium-date"
                    type="date"
                    value={form.sodium_date}
                    name="sodium_date"
                    onChange={handleChange}
                    style={styles.inputFields}
                  />
                  <FormHelperText id="sodium-date" sx={{ color: "red" }}>
                    {errors.sodium_date && errors.sodium_date[0]}
                  </FormHelperText>
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.sodium && errors.sodium[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"70%"}>
                  <InputLabel htmlFor="potasium">{t("potassium")}</InputLabel>
                  <TextField
                    fullWidth
                    id="potasium"
                    type="number"
                    value={form.potassium}
                    name="potassium"
                    onChange={handleChange}
                  />
                </Box>
                <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                  <InputLabel htmlFor="potasium-date">{t("date")}</InputLabel>
                  <input
                    id="potasium-date"
                    type="date"
                    value={form.potassium_date}
                    name="potassium_date"
                    onChange={handleChange}
                    style={styles.inputFields}
                  />
                  <FormHelperText id="potasium-date" sx={{ color: "red" }}>
                    {errors.potassium_date && errors.potassium_date[0]}
                  </FormHelperText>
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.potassium && errors.potassium[0]}
              </FormHelperText>
            </Box>
            <Box>
              <Box sx={styles.row}>
                <Box width={"70%"}>
                  <InputLabel htmlFor="potasium">
                    {t("target_weight")}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="target_weight"
                    type="number"
                    value={form.target_weight}
                    name="target_weight"
                    onChange={handleChange}
                  />
                </Box>
                <Box width={"30%"} sx={{ alignSelf: "self-end" }}>
                  <InputLabel htmlFor="target_weight-date">
                    {t("date")}
                  </InputLabel>
                  <input
                    id="target_weight_date-date"
                    type="date"
                    value={form.target_weight_date}
                    name="target_weight_date"
                    onChange={handleChange}
                    style={styles.inputFields}
                  />
                  <FormHelperText id="target_weight-date" sx={{ color: "red" }}>
                    {errors.target_weight_date && errors.target_weight_date[0]}
                  </FormHelperText>
                </Box>
              </Box>
              <FormHelperText id="name-input-error-text" sx={{ color: "red" }}>
                {errors.target_weight && errors.target_weight[0]}
              </FormHelperText>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={() => navigate(-1)}
            sx={[styles.buttons, { background: "#D04444", width: "232px" }]}
          >
            {t("cancel_modification")}
          </Button>
          <Button
            onClick={() => {
              updateMedicalInformation();
            }}
            sx={[styles.buttons, { background: "#5EC360", width: "285px" }]}
          >
            {t("modify_medical_info")}
          </Button>
        </Box>
      </Box>
    </div>
  );
};
export default withMessages(ModifyMedicalInformationICC);
