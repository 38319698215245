import * as React from "react";
import { Grid, InputLabel } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const HealthBackground = (props) => {
  const { t } = useTranslation();
  const styles = {
    container: {
      width: "100%",
      borderRadius: "18px",
      backgroundColor: "#F4F5F8",
      padding: "15px",
      marginTop: "20px",
    },
    title: {
      color: "#3657D9",
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "600",
      paddingBottom: "10px",
    },
    labels: {
      whiteSpace: "pre-wrap",
      marginLeft: "20px",
    },
  };

  return (
    <Box sx={styles.container}>
      <InputLabel sx={styles.title}>{t("history_helth")}</InputLabel>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <InputLabel sx={styles.labels}>
            <b>{t("accident_ccp")}: </b>
            {props.healthBackground?.acv?.value == 1 ? "SI" : "NO"}
          </InputLabel>
          <Box display={"flex"}>
            <InputLabel sx={styles.labels}>
              <b>• {t("sequel")}: </b>
              {props.healthBackground?.acv_sequel?.value ?? " - "}
            </InputLabel>
            <InputLabel sx={styles.labels}>
              <b>{t("date")}: </b>
              {props.healthBackground?.acv_date?.value ?? " - "}
            </InputLabel>
          </Box>
          <InputLabel sx={[styles.labels, { marginTop: "10px" }]}>
            <b>{t("pregnancy_in_curs")}: </b>
            {props.healthBackground?.ongoing_pregnancy?.value == 1
              ? "SI"
              : "NO"}
          </InputLabel>
        </Box>
        <Box>
          <InputLabel sx={styles.labels}>
            <b>{t("previus_heart_attack")}: </b>
            {props.healthBackground?.myocardial_infarction?.value == 1
              ? "SI"
              : "NO"}
          </InputLabel>
          <Box display={"flex"}>
            <InputLabel sx={styles.labels}>
              <b>• {t("sequel")}: </b>
              {props.healthBackground?.myocardial_infarction_sequel?.value ??
                " - "}
            </InputLabel>
            <InputLabel sx={styles.labels}>
              <b>{t("date")}: </b>
              {props.healthBackground?.myocardial_infarction_date?.value ??
                " - "}
            </InputLabel>
          </Box>
          <InputLabel sx={[styles.labels, { marginTop: "10px" }]}>
            <b>{t("atrial_fibrillation")}: </b>
            {props.healthBackground?.atrial_fibrillation?.value == 1
              ? "SI"
              : "NO"}
          </InputLabel>
        </Box>
        <Box>
          <InputLabel sx={styles.labels}>
            <b>{t("acute_lung_edema")}: </b>
            {props.healthBackground?.acute_edema?.value == 1 ? "SI" : "NO"}
          </InputLabel>
          <InputLabel sx={[styles.labels, { marginTop: "10px" }]}>
            <b>{t("anticoagulation")}: </b>
            {props.healthBackground?.anticoagulation?.value == 1 ? "SI" : "NO"}
          </InputLabel>
        </Box>
      </Box>
    </Box>
  );
};
export default HealthBackground;
