import { Box } from "@mui/material";

const ColorBarMeasurement = (props) => {
  const styles = {
    container: {
      height: "30px",
      width: "100%",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
      display: "flex"
    },
    title: {
      color: "#575565",
      fontSize: "10px",
      fontWeight: "600",
      fontFamily: "Poppins",
      margin: "10px 0 5px",
    },
    divisorLine: {
      width: "2px",
      height: "6px",
      background: "#737589"
    },
    labels: {
      textAlign: "center",
      fontSize: "8px",
      fontFamily: "Poppins",
      fontWeight: "500"
    },
    value: {
      position: "relative", 
      left: props.percentage >= 1 ? "90%" : props.centerColor? "calc(80% * " + props.percentage + ")" : "calc(10% + 20% * " + props.value + ")",
      width: "25px",
      height: "25px",
      transform: "translate(-50%, -50%)",
      top: "-31px",
      borderRadius: "50%",
      border: "2px solid #737589",
      background: "#FCFCFC",
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.05)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      text: {
        color: "#737589",
        fontSize: "8px",
        fontWeight: "600",
        textAlign: "center",
        marginTop: "1px"
      }
    }
  }

  return (
    <Box>
      <Box sx={styles.title}>
        {props.title}
      </Box>
      <Box sx={styles.container}>
        <Box sx={[{ background: props.colors[0], borderRadius: "8px 0 0 8px", width: "20%" }]}></Box>
        <Box sx={[{ background: props.colors[0], width: "20%" }]}></Box>
        <Box sx={[{ background: props.colors[1], width: "20%" }]}></Box>
        <Box sx={[{ background: props.colors[1], width: "20%" }]}></Box>
        <Box sx={[{ background: props.colors[2], borderRadius: "0 8px 8px 0", width: "20%" }]}></Box>
      </Box>
      <Box sx={[{ position: "relative", bottom: "6px", left: "calc(20% - 1px)" }, styles.divisorLine]}></Box>
      <Box sx={[{ position: "relative", bottom: "12px", left: "calc(40% - 1px)" }, styles.divisorLine]}></Box>
      <Box sx={[{ position: "relative", bottom: "18px", left: "calc(60% - 1px)" }, styles.divisorLine]}></Box>
      <Box sx={[{ position: "relative", bottom: "24px", left: "calc(80% - 1px)" }, styles.divisorLine]}></Box>
      {
        props.centerColor ?
          <Box display={"flex"} mt="-20px">
            <Box sx={[{ width: "40%" }, styles.labels]}>{props.labels[0]}</Box>
            <Box sx={[{ width: "40%" }, styles.labels]}>{props.labels[1]}</Box>
            <Box sx={[{ width: "20%" }, styles.labels]}>{props.labels[2]}</Box>
          </Box> :
          <Box display={"flex"} mt="-20px">
            <Box sx={[{ width: "20%" }, styles.labels]}>{props.labels[0]}</Box>
            <Box sx={[{ width: "20%" }, styles.labels]}>{props.labels[1]}</Box>
            <Box sx={[{ width: "20%" }, styles.labels]}>{props.labels[2]}</Box>
            <Box sx={[{ width: "20%" }, styles.labels]}>{props.labels[3]}</Box>
            <Box sx={[{ width: "20%" }, styles.labels]}>{props.labels[4]}</Box>
          </Box>
      }
      <Box sx={styles.value}>
        <Box sx={styles.value.text}>{props.value}</Box>
      </Box>
    </Box>
  )
}
export default ColorBarMeasurement;