import * as React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import dotenv from "react-dotenv";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import theme from "../../theme/vs-theme";
import { useNavigate, useLocation } from "react-router-dom";
import { FormControl } from "@mui/material";
import { Avatar, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Select from "@mui/material/Select";
import { TextField, SelectSx } from "../../theme/vs-theme";
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import withMessages from "../../components/WithMessages";
import CircularProgress from "@mui/material/CircularProgress";
import countries from "./ConstCod";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const headers = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
};

const RegisterPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);

  const email = queryParams.get("email");
  const [previewSrc, setPreviewSrc] = React.useState("");
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    password_confirmation: false,
  });
  const [form, setForm] = React.useState({
    avatar: "",
    email: email ? email : "",
    password: "",
    password_confirmation: "",
    name: "",
    lastname: "",
    phone_country_code: "",
    phone: "",
    id_type: 1,
    id_number: "",
    medical_license_type_id: "",
    medical_license_code: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleClickShowPassword = (event) => {
    setShowPassword({
      ...showPassword,
      [event.target.name]: !showPassword[event.target.name],
    });
  };
  const handleMouseDownPassword = (event) => event.preventDefault();

  const setAvatar = (event) => {
    const file = event.target.files[0];
    if (file) {
      setErrors({ ...errors, avatar: null });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setForm({ ...form, avatar: file });
    }
  };

  const handleRegister = () => {
    setLoading(true);
    setErrors({});
    axios
      .post(dotenv.API_URL + "/doctors", form, headers)
      .then((response) =>
        props.showMessage(t("user_created"), () =>
          login(response.data, "/profile")
        )
      )
      .catch((error) => {
        setErrors(error.response.data?.errors);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          background:
            "linear-gradient(180deg, rgba(225, 244, 252, 0.5) 0%, rgba(122, 194, 235, 0.5) 57.86%, rgba(110, 188, 238, 0.5) 64.58%, rgba(75, 133, 196, 0.5) 100%), #FFFFFF",
          borderRadius: "2px",
          p: 4,
        }}
      >
        <Grid container component="main" flexDirection="row" spacing={0}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 0,
              mt: 0,
            }}
          >
            <img src="logo.png" alt="logo" width={320} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              mx: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Button
              tabIndex={14}
              variant="contained"
              color="primary"
              startIcon={<img src="/back-white.png" alt="" />}
              onClick={() => navigate("/login")}
            >
              {t("goback")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                mx: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 4,
                boxShadow: "none",
                borderRadius: 4,
              }}
            >
              <Box sx={{ mt: 1 }}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "left",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ textAlign: "center", mb: 2, color: "#3657D9" }}
                    >
                      {t("register")}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Avatar
                      alt="avatar"
                      src={previewSrc}
                      sx={{
                        width: "60px",
                        height: "60px",
                        border: 4,
                        backgroundColor: "#D9D9D9",
                        borderColor: "white",
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={11}>
                    <label htmlFor="avatar-file">
                      <input
                        name="avatar"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onInput={setAvatar}
                        accept="image/*"
                        id="avatar-file"
                        type="file"
                        style={{ display: "none" }}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        endIcon={<img src={"/plus.png"} alt={"Avatar"} />}
                        sx={{ width: 200, ml: 3 }}
                      >
                        {t("add_photo")}
                      </Button>
                      <FormHelperText
                        id="avatar-file-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.avatar && errors.avatar[0]}
                      </FormHelperText>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" required>
                      <InputLabel shrink htmlFor="email-input">
                        {t("email")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "1" }}
                        id="email-input"
                        name="email"
                        value={form.email}
                        disabled={email ? true : false}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="email-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.email && errors.email[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" required>
                      <InputLabel shrink htmlFor="name-input">
                        {t("name")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "4" }}
                        id="name-input"
                        name="name"
                        value={form.name}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="name-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.name && errors.name[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined">
                      <InputLabel shrink htmlFor="id-type-input" sx={{ mt: 1 }}>
                        {t("type_identification")}
                      </InputLabel>
                      <Select
                        inputProps={{ tabIndex: "8" }}
                        sx={SelectSx}
                        id="id-type-input"
                        name="id_type"
                        value={form.id_type}
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>DNI</MenuItem>
                        <MenuItem value={5}>CUIT</MenuItem>
                        <MenuItem value={6}>CUIL</MenuItem>
                      </Select>
                      <FormHelperText
                        id="id-type-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.id_type && errors.id_type[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" required>
                      <InputLabel shrink htmlFor="password-input">
                        {t("password")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "2" }}
                        type={showPassword.password ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickShowPassword({
                                  target: { name: "password" },
                                })
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword.password ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="password-input"
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="password-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.password && errors.password[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" required>
                      <InputLabel shrink htmlFor="lastname-input">
                        {t("lastname")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "5" }}
                        id="lastname-input"
                        name="lastname"
                        value={form.lastname}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="lastname-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.lastname && errors.lastname[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard">
                      <InputLabel shrink htmlFor="id-number-input">
                        {t("number_identification")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "9" }}
                        id="id-number-input"
                        name="id_number"
                        value={form.id_number}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="id-number-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.id_number && errors.id_number[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" required>
                      <InputLabel shrink htmlFor="password_confirmation-input">
                        {t("confirm_password")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "3" }}
                        type={
                          showPassword.password_confirmation
                            ? "text"
                            : "password"
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickShowPassword({
                                  target: { name: "password_confirmation" },
                                })
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword.password_confirmation ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        id="password_confirmation-input"
                        name="password_confirmation"
                        value={form.password_confirmation}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="password_confirmation-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.password_confirmation &&
                          errors.password_confirmation[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined">
                      <InputLabel
                        shrink
                        htmlFor="phone_country_code-input"
                        sx={{ mt: 1 }}
                      >
                        {t("country_code")}
                      </InputLabel>
                      <Select
                        inputProps={{ tabIndex: "6" }}
                        sx={SelectSx}
                        id="cphone_country_code-input"
                        name="phone_country_code"
                        value={form.phone_country_code}
                        onChange={handleChange}
                      >
                        {countries.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {`${country.name} (${country.code})`}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        id="phone_country_code-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.phone_country_code &&
                          errors.phone_country_code[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined">
                      <InputLabel
                        shrink
                        htmlFor="medical_license_type_id-input"
                        sx={{ mt: 1 }}
                      >
                        {t("license_type")}
                      </InputLabel>
                      <Select
                        inputProps={{ tabIndex: "10" }}
                        sx={SelectSx}
                        id="medical_license_type_id-input"
                        name="medical_license_type_id"
                        value={form.medical_license_type_id}
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Nacional</MenuItem>
                        <MenuItem value={2}>Buenos Aires</MenuItem>
                        <MenuItem value={3}>Catamarca</MenuItem>
                        <MenuItem value={4}>Chaco</MenuItem>
                        <MenuItem value={5}>Chubut</MenuItem>
                        <MenuItem value={6}>Córdoba</MenuItem>
                        <MenuItem value={7}>Corrientes</MenuItem>
                        <MenuItem value={8}>Entre Ríos</MenuItem>
                        <MenuItem value={9}>Formosa</MenuItem>
                        <MenuItem value={10}>Jujuy</MenuItem>
                        <MenuItem value={11}>La Pampa</MenuItem>
                        <MenuItem value={12}>La Rioja</MenuItem>
                        <MenuItem value={13}>Mendoza</MenuItem>
                        <MenuItem value={14}>Misiones</MenuItem>
                        <MenuItem value={15}>Neuquén</MenuItem>
                        <MenuItem value={16}>Río Negro</MenuItem>
                        <MenuItem value={17}>Salta</MenuItem>
                        <MenuItem value={18}>San Juan</MenuItem>
                        <MenuItem value={19}>San Luis</MenuItem>
                        <MenuItem value={20}>Santa Cruz</MenuItem>
                        <MenuItem value={21}>Santa Fe</MenuItem>
                        <MenuItem value={22}>Santiago Del Estero</MenuItem>
                        <MenuItem value={23}>
                          Tierra del Fuego, Antártida e Islas del Atlántico Sur
                        </MenuItem>
                        <MenuItem value={24}>Tucumán</MenuItem>
                      </Select>
                      <FormHelperText
                        id="id-type-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.medical_license_type_id &&
                          errors.medical_license_type_id[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}></Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" required>
                      <InputLabel shrink htmlFor="phone-input">
                        {t("phone")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "7" }}
                        id="phone-input"
                        name="phone"
                        value={form.phone}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="phone-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.phone && errors.phone[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="standard" required>
                      <InputLabel shrink htmlFor="medical_license_code-input">
                        {t("license_number")}
                      </InputLabel>
                      <TextField
                        inputProps={{ tabIndex: "11" }}
                        id="medical_license_code-input"
                        name="medical_license_code"
                        value={form.medical_license_code}
                        onChange={handleChange}
                      />
                      <FormHelperText
                        id="medical_license_code-input-error-text"
                        sx={{ color: "red" }}
                      >
                        {errors.medical_license_code &&
                          errors.medical_license_code[0]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      disabled={loading}
                      tabIndex={13}
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleRegister}
                    >
                      {t("signup")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiThemeProvider>
  );
};

export default withMessages(RegisterPage);
