import React from "react";
import { Box } from "@mui/material";
import LineChartsMultipleMeasurement from "./LineChartsMultipleMeasurement";
import LineChartsMeasurement from "./LineChartsMeasurement";
import { useTranslation } from "react-i18next";
const HTACharts = (props) => {
  const { t } = useTranslation();

  const boxStyle = {
    width: "100%",
    padding: "20px 30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "18px",
    margin: "10px 0px",
    title: {
      fontFamily: "Poppins",
      color: "#737589",
      fontSize: "22px",
      fontWeight: 600,
      textAlign: "center",
      marginBottom: "20px",
    },
  };
  return (
    <>
      {props.modality == 1 ? // IF PROTOCOL SHOW PROTOCOL CHARTS
        <>
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_ta")}</Box>
            <LineChartsMeasurement
              slug="general-systolic-pressure"
              slug2="general-diastolic-pressure"
              firstTitle="Sistólica"
              secondTitle="Diastólica"
              name="Presión arterial"
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={[
                props?.json?.blood_pressure_general.systolic,
                props?.json?.blood_pressure_general.diastolic,
              ]} />
            <Box sx={boxStyle.title}>{t("history_fcg")}</Box>
            <LineChartsMeasurement
              slug="general-heart-rate"
              name="Frecuencia cardiaca"
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={props?.json?.heart_rate_general} />
          </Box><Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_tam")}</Box>
            <LineChartsMultipleMeasurement
              slug="morning-systolic-pressure"
              slug2="morning-diastolic-pressure"
              firstTitle="Sistólica M1"
              first2Title="Sistólica M2"
              secondTitle="Diastólica M1"
              second2Title="Diastólica M2"
              avgTitle="Sistólica Promedio"
              avg2Title="Diastólica Promedio"
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={[
                props?.json?.blood_pressure_morning?.systolic,
                props?.json?.blood_pressure_morning?.diastolic,
              ]} />
            <Box sx={boxStyle.title}>{t("history_fcm")}</Box>
            <LineChartsMultipleMeasurement
              slug="morning-heart-rate"
              firstTitle="M1"
              first2Title="M2"
              avgTitle="Promedio"
              patient={props.id}
              count={props.count}
              startDate={props.startDate}
              endDate={props.endDate}
              chartImage={props.saveChartImage}
              jsonComplete={[props?.json?.heart_rate_morning]} />
          </Box><Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_tav")}</Box>
            <LineChartsMultipleMeasurement
              slug="afternoon-systolic-pressure"
              slug2="afternoon-diastolic-pressure"
              firstTitle="Sistólica M1"
              first2Title="Sistólica M2"
              secondTitle="Diastólica M1"
              second2Title="Diastólica M2"
              avgTitle="Sistólica Promedio"
              avg2Title="Diastólica Promedio"
              patient={props.id}
              count={props.count}
              startDate={props.startDate}
              endDate={props.endDate}
              chartImage={props.saveChartImage}
              jsonComplete={[
                props?.json?.blood_pressure_afternoon?.systolic,
                props?.json?.blood_pressure_afternoon?.diastolic,
              ]} />
            <Box sx={boxStyle.title}>{t("historico_fcv")}</Box>
            <LineChartsMultipleMeasurement
              slug="afternoon-heart-rate"
              firstTitle="M1"
              first2Title="M2"
              avgTitle="Promedio"
              patient={props.id}
              count={props.count}
              startDate={props.startDate}
              endDate={props.endDate}
              chartImage={props.saveChartImage}
              jsonComplete={[props?.json?.heart_rate_afternoon]} />

          </Box>
        </> : // IF MAINTENANCE SHOW MAINTENANCE CHARTS
        <> 
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_blood_pressure")}</Box>
            <LineChartsMeasurement
              slug="systolic-blood-pressure"
              slug2="diastolic-blood-pressure"
              firstTitle="Sistólica"
              secondTitle="Diastólica"
              name="Presión arterial"
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={[
                props?.json?.graph_systolic_blood_pressure,
                props?.json?.graph_diastolic_blood_pressure,
              ]}
            />
          </Box>
          <Box sx={boxStyle}>
            <Box sx={boxStyle.title}>{t("history_heart_rate")}</Box>
            <LineChartsMeasurement
              slug="heart-rate"
              name="Frecuencia cardiaca"
              patient={props?.id}
              count={props?.count}
              startDate={props?.startDate}
              endDate={props?.endDate}
              chartImage={props?.saveChartImage}
              jsonComplete={props?.json?.graph_heart_rate}
            />
          </Box>
        </>
      }
    </>
  );
};
export default HTACharts;
