import React from "react";
import { Box, IconButton, MenuItem, Select } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import { useLocation, useParams } from "react-router-dom";
import MaterialTable from "../../../components/MaterialTable";
import { useTranslation } from "react-i18next";

const PatientReportsIndexPage = (props) => {
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = React.useState({});
  const [isError, setIsError] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState(6)
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  
  const columns = React.useMemo(() => [
    {
      id: "created_at",
      header: t("date"),
      accessorKey: "created_at",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      id: "type",
      header: t("type"),
      accessorKey: "type",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
        sx: {
          fontFamily: "Poppins",
          color: "#667085",
          fontSize: "12px",
          fontWeight: 600,
          textAlign: "center",
        },
      },
    },
    {
      id: "action",
      header: t("actions"),
      Cell: (cell) => (
        <IconButton
          onClick={() => downloadPDF(cell.row.original.id)}
        >
          <img src={"/pdf.png"} />
        </IconButton>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ], [t]);

  React.useEffect(() => {
    props.setTitle(["Pacientes", location.state.patient]);
    getReports();
  }, []);

  React.useEffect(() => {
    getReports();
  }, [sorting, pagination, selectedType]);

  const handleChange = (evt) => {
    setSelectedType(evt.target.value)
  }

  const downloadPDF = (reportId) => {
    const url = new URL(`/institutions/v1/reports/${reportId}`, dotenv.API_URL);
    axios.get(url.href, {
      responseType: "blob",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }).then((response) => {
      const blobUrl = URL.createObjectURL(
        new Blob([response.data], { type: "pdf" })
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "report.pdf");
      link.click();
    })
    .catch((error) => console.log(error))
    .finally(() => {
    })
  }

  const getReports = () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    const url = new URL(
      `/institutions/v1/patients/${id}/reports/`,
      dotenv.API_URL
    );
    url.searchParams.set("page", `${pagination.pageIndex + 1}`);
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    selectedType != 6? url.searchParams.set("type[]", selectedType) : url.searchParams.set("type", "");
    url.searchParams.set("modality", selectedType == 0 ? 1 : selectedType == 6? 0 : 2);
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setData(response.data?.data);
        setRowCount(response.data?.total);
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false);
        setIsRefetching(false);
      });
  };

  const styles = {
    container: {
      width: "100%",
      padding: "20px 30px",
      backgroundColor: "#FFFFFF",
      borderRadius: "18px",
      margin: "10px 0px",
    },
    date: {
      fontFamily: "Poppins",
      color: "#667085",
      fontSize: "14px",
      textAlign: "center",
    },
    select: {
      backgroundColor: "#FFF",
      borderRadius: "12px",
      border: "1px solid #869AE8",
      color: "#869AE8",
      height: "38px",
      minWidth: "190px",
    },
    filterText: {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Poppins"
    }
  };
  return (
    <>
      <Box sx={styles.container}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"10px"}>
          <Box sx={styles.filterText}>Flitrar por tipo:</Box>
          <Select
            sx={styles.select}
            id="type"
            name="type"
            value={selectedType}
            onChange={handleChange}
          >
            {location.state.pathology == 'hta' ?
              <MenuItem value={0} key={0}>
                {t("protocol")}
              </MenuItem> : null
            }
            <MenuItem value={1} key={1}>
              {t("monthly")}
            </MenuItem>     
            <MenuItem value={2} key={2}>
              {t("last_week")}
            </MenuItem>     
            <MenuItem value={3} key={3}>
              {t("last_2_weeks")}
            </MenuItem>     
            <MenuItem value={4} key={4}>
              {t("last_3_weeks")}
            </MenuItem>     
            <MenuItem value={5} key={5}>
              {t("since_vs")}
            </MenuItem>     
            <MenuItem value={6} key={6}>
              {t("all")}
            </MenuItem>     
          </Select>
        </Box>
        <MaterialTable
          columns={columns}
          data={data}
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: t("error_fetching_data"),
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
      </Box>
    </>
  );
};

export default PatientReportsIndexPage;
