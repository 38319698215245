import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";

const HTATables = (props) => {
  
  const [columns, setColumns] = React.useState([]);
  const rowSlugs = ["morning_1", "morning_2", "morning_average", "afternoon_1", "afternoon_2", "afternoon_average", "daily_average"];
  const rows = ["Matutino 1", "Matutino 2", "Promedio Matutino", "Vespertino 1", "Vespertino 2", "Promedio Vespertino", "Promedio Diario"];
  
  React.useEffect(()=> {
    if (props.data) {
      let keys = [];
      Object.keys(props?.data).forEach(item => {
        keys.push(item);
      });
      setColumns([...keys]);
    }
  },[props.data])
  
  const styles = {
    title: {
      color: "#575565",
      fontSize: "12px",
      fontWeight: "700",
    },
    headers: {
      fontSize: "11px",
      fontWeight: "600",
      lineHeight: "20px",
      color: "#737589",
    },
    cells: {
      fontSize: "11px",
      fontWeight: "400",
      lineHeight: "20px",
      color: "#667085",
      textAlign: "center"
    },
  }
  return (
    columns.length > 0 ?
      <TableContainer component={Paper} sx={{ borderRadius: "18px", mt: "10px" }}>
        <Table>
          <TableHead sx={{ position: "sticky", top: "0px", margin: "0 0" }}>
            <TableRow sx={{ background: "#F9FAFB" }}>
              <TableCell sx={styles.title}>{props.title}</TableCell>
              {
                columns?.map((col) => <TableCell key={col} sx={[styles.headers, {textAlign: "center"}]}>{col}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={styles.headers}>{row}</TableCell>
                  {
                    columns?.map(
                      (col) => 
                      <TableCell key={props?.data[col][rowSlugs[index]]} sx={styles.cells}>
                        {props.title === "Presión Arterial" ? props?.data[col][rowSlugs[index]].systolic && props?.data[col][rowSlugs[index]].diastolic
                          ? props?.data[col][rowSlugs[index]].systolic + '/' + props?.data[col][rowSlugs[index]].diastolic
                          : '- / -'
                          : props?.data[col][rowSlugs[index]].heart_rate || '-'}
                    </TableCell>
                      )
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      : <></>
  );
}
export default HTATables;