import * as React from "react";
import { Avatar, Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import dotenv from "react-dotenv";
import { TextField } from "../../theme/vs-theme";
import WithMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const AdminCustomerCreatePage = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [previewSrc, setPreviewSrc] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [form, setForm] = React.useState({
    name: "",
    lastname: "",
    phone: "",
    email: "",
    avatar: null,
    password: "admin",
    customer: { id },
  });
  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.customer) {
      props.setTitle(["Agrupadores", location.state.customer], "");
    }
  }, []);

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const setAvatar = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setForm({ ...form, avatar: file });
    }
  };

  const saveAdmin = () => {
    setIsLoading(true);
    axios
      .post(
        new URL("/institutions/v1/admins/customers", dotenv.API_URL).href,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() =>
        props.showMessage(t("admin_created_successfully"), () =>
          navigate(-1, {
            state: {
              customer: location.state?.customer,
            },
          })
        )
      )
      .catch((error) => setErrors(error.response.data?.errors))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Grid>
        <Avatar
          alt="avatar"
          src={previewSrc}
          sx={{
            width: "80px",
            height: "80px",
            border: 4,
            backgroundColor: "#D9D9D9",
            borderColor: "white",
          }}
        />
      </Grid>
      <Grid xs={2}>
        <label htmlFor="avatar-file">
          <input
            name="avatar"
            onClick={(event) => {
              event.target.value = null;
            }}
            onInput={setAvatar}
            accept="image/*"
            id="avatar-file"
            type="file"
            style={{ display: "none" }}
          />
          <Button
            variant="contained"
            component="span"
            color="primary"
            endIcon={<img src={"/plus.png"} alt={"Avatar"} />}
            sx={{ width: 200, ml: 3 }}
          >
            {t("add_photo")}
          </Button>
        </label>
      </Grid>
      <Grid xs={12} sx={{ mt: 4 }}>
        <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
          <Grid container spacing={4}>
            <Grid xs={3}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="name-input">
                  {t("name")}
                </InputLabel>
                <TextField
                  id="name-input"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="name-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.name && errors.name[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={3}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="lastname-input">
                  {t("lastname")}
                </InputLabel>
                <TextField
                  id="lastname-input"
                  name="lastname"
                  value={form.lastname}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="lastname-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.lastname && errors.lastname[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={2}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="phone-input">
                  {t("phone")}
                </InputLabel>
                <TextField
                  id="phone-input"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="phone-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.phone && errors.phone[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={4}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="email-input">
                  {t("email")}
                </InputLabel>
                <TextField
                  id="email-input"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="email-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.email && errors.email[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" spacing={4}>
            <Grid xs={2}>
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  navigate(-1, {
                    state: {
                      customer: location.state?.customer,
                    },
                  })
                }
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                color="success"
                onClick={saveAdmin}
                disabled={isLoading}
              >
                {t("enrollment")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default WithMessages(AdminCustomerCreatePage);
