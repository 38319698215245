import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

const MaterialTable = (props) => {

  return (
    <MaterialReactTable
      {...props}
      muiTablePaperProps={{
        sx: {
          borderRadius: '24px',
          boxShadow: 'none',
          backgroundColor: '#F9FAFB',
          'margin': '0px'
        },
      }}
      muiTopToolbarProps={{
        sx: {
          'borderRadius': '24px 24px 0px 0px',
          'backgroundColor': '#F9FAFB',
          'margin': '0px',
          'padding': '5px'
        }
      }}
      muiTableHeadCellProps={{
        sx: {
          'backgroundColor': '#F9FAFB',
          'color': '#737589',
          'fontSize': '12px',
          'margin': '0px',
          'padding': '5px',
          'textAlign': 'center'
        }
      }}
      muiTableBodyCellProps={{
        sx: {
          'color': '#8D8F9D',
          'fontSize': '12px',
          'margin': '0px',
          'padding': '5px'
        }
      }}
      manualFiltering
      manualPagination
      enableStickyHeader
      enableStickyFooter
      manualSorting
      localization={MRT_Localization_ES}
      initialState={{ showColumnFilters: false }}

      muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10],
        showFirstButton: true,
        showLastButton: true
      }}

    />
  )
}

export default MaterialTable;