import React from "react";
import LineChartsMeasurement from "./LineChartsMeasurement";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
const ICCCharts = (props) => {
  const { t } = useTranslation();
  const boxStyle = {
    width: "100%",
    padding: "20px 30px",
    backgroundColor: "#FFFFFF",
    borderRadius: "18px",
    margin: "10px 0px",
    title: {
      fontFamily: "Poppins",
      color: "#737589",
      fontSize: "22px",
      fontWeight: 600,
      textAlign: "center",
      marginBottom: "20px",
    },
  };
  return (
    <>
      <Box sx={boxStyle}>
        <Box sx={boxStyle.title}>{t("history_how_i_feel")}</Box>
        <LineChartsMeasurement
          slug="how-i-feel"
          patient={props?.id}
          count={props?.count}
          startDate={props?.startDate}
          endDate={props?.endDate}
          chartImage={props?.saveChartImage}
          jsonComplete={props?.json?.graph_how_i_feel}
        />
      </Box>
      <Box sx={boxStyle}>
        <Box sx={boxStyle.title}>{t("history_weight")}</Box>
        <LineChartsMeasurement
          slug="weight"
          name="Peso"
          patient={props?.id}
          count={props?.count}
          startDate={props?.startDate}
          endDate={props?.endDate}
          chartImage={props?.saveChartImage}
          jsonComplete={props?.json?.graph_weight}
        />
      </Box>
      <Box sx={boxStyle}>
        <Box sx={boxStyle.title}>{t("history_blood_pressure")}</Box>
        <LineChartsMeasurement
          slug="systolic-blood-pressure"
          slug2="diastolic-blood-pressure"
          firstTitle="Sistólica"
          secondTitle="Diastólica"
          name="Presión arterial"
          patient={props?.id}
          count={props?.count}
          startDate={props?.startDate}
          endDate={props?.endDate}
          chartImage={props?.saveChartImage}
          jsonComplete={[
            props?.json?.graph_systolic_blood_pressure,
            props?.json?.graph_diastolic_blood_pressure,
          ]}
        />
      </Box>
      <Box sx={boxStyle}>
        <Box sx={boxStyle.title}>{t("history_heart_rate")}</Box>
        <LineChartsMeasurement
          slug="heart-rate"
          name="Frecuencia cardiaca"
          patient={props?.id}
          count={props?.count}
          startDate={props?.startDate}
          endDate={props?.endDate}
          chartImage={props?.saveChartImage}
          jsonComplete={props?.json?.graph_heart_rate}
        />
      </Box>
      <Box sx={boxStyle}>
        <Box sx={boxStyle.title}>{t("history_oxygen-saturation")}</Box>
        <LineChartsMeasurement
          slug="oxygen-saturation"
          name="Saturación de oxigeno"
          patient={props?.id}
          count={props?.count}
          startDate={props?.startDate}
          endDate={props?.endDate}
          chartImage={props?.saveChartImage}
          jsonComplete={props?.json?.graph_oxygen_saturation}
        />
      </Box>
    </>
  );
};
export default ICCCharts;
